import { createContext, useState } from "react";
import "./App.scss";
import { createHashRouter, RouterProvider } from "react-router-dom";
import Login from "./component/onboarding/Login";
import Forget from "./component/onboarding/Forget";
import Verification from "./component/onboarding/Verification";
import ResetPassword from "./component/onboarding/ResetPassword";
import OnboardLayout from "./component/layouts/OnboardLayout";
import { ToastContainer } from "react-toastify";
import "react-toastify/ReactToastify.min.css";
import DashboardLayout from "./component/layouts/DashboardLayout";
import Dashboard from "./component/dashboard/Dashboard";
import { Testimonial } from "./component/testimonial/Testimonial";
import { User } from "./component/user/User";
import { Blogs } from "./component/blogs/Blogs";
import { Projects } from "./component/projects/Projects";
import { ChangePassword } from "./component/change_password/ChangePassword";
import { UserDetails } from "./component/user/UserDetails";
import { UserPostDetails } from "./component/user/UserPostDetails";
import { UserPost } from "./component/user/UserPost";
import { AddTestimonials } from "./component/testimonial/AddTestimonials";
import { TestimonialsDetails } from "./component/testimonial/TestimonialsDetails";
import { AddBlog } from "./component/blogs/AddBlog";
import { BlogDetails } from "./component/blogs/BlogDetails";
import { ProjectDetails } from "./component/projects/ProjectDetails";
import Spinner from "./component/common-Component/Spinner";
import { EditUser } from "./component/user/EditUser";
import { EditBlog } from "./component/blogs/EditBlog";
import React from "react";

export const router = createHashRouter([
  {
    path: "/",
    element: <OnboardLayout />,
    children: [
      {
        path: "",
        element: <Login />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "forget_password",
        element: <Forget />,
      },
      {
        path: "verification_code",
        element: <Verification />,
      },
      {
        path: "reset_password",
        element: <ResetPassword />,
      },
    ],
  },

  {
    path: "/",
    element: <DashboardLayout />,
    children: [
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "users",
        element: <User />,
      },
      {
        path: "/users/user-details/:id",
        element: <UserDetails />,
      },
      {
        path: "/users/user-edit/:id",
        element: <EditUser />,
      },
      {
        path: "/users/user-post/:id",
        element: <UserPost />,
      },
      {
        path: "/users/user-post-details/:id",
        element: <UserPostDetails />,
      },
      {
        path: "testimonials",
        element: <Testimonial />,
      },
      {
        path: "testimonials/edit-testimonials/:id",
        element: <AddTestimonials />,
      },
      {
        path: "testimonials/add-testimonials",
        element: <AddTestimonials />,
      },
      {
        path: "testimonials/testimonials-details/:id",
        element: <TestimonialsDetails />,
      },
      {
        path: "blogs/add-blog",
        element: <AddBlog />,
      },
      {
        path: "blogs/edit-blog/:id",
        element: <EditBlog />,
      },
      {
        path: "blogs",
        element: <Blogs />,
      },
      {
        path: "blogs/blog-details/:id",
        element: <BlogDetails />,
      },
      {
        path: "projects",
        element: <Projects />,
      },
      {
        path: "projects/project-details/:id",
        element: <ProjectDetails />,
      },
      {
        path: "change-password",
        element: <ChangePassword />,
      },
    ],
  },
]);

//  context code ==========
export const GlobalContext = createContext(null);

function App() {
  const [spinner, setSpinner] = useState(false);
  const [toggleBtn, settoggleBtn] = useState(false);
  const [adminName, setAdminName] = useState("");
  let [showUserImage, setShowUserImage] = useState("");

  return (
    <>
      {spinner && <Spinner />}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <GlobalContext.Provider
        value={{
          setSpinner,
          settoggleBtn,
          setAdminName,
          adminName,
          toggleBtn,
          showUserImage,
          setShowUserImage,
        }}
      >
        <RouterProvider router={router} />
      </GlobalContext.Provider>
    </>
  );
}

export default App;
