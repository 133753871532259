import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios, { headers } from "../../http/axios";
import { GlobalContext } from "../../App";
import { toast } from "react-toastify";
import BackNavigate from "../common-Component/BackNavigate";
import CommonInput from "../common-Component/form/CommonInput";
import { useForm } from "react-hook-form";
import { Controller } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

// ... your other imports

export function EditUser() {
  const MAX_IMAGE_SIZE = 1024 * 1024; // 1MB in bytes
  const [userDetails, setUserDetails] = useState();
  const { setSpinner } = useContext(GlobalContext);
  // const [eventBanner, setEventBanner] = useState();
  const [imageData, setImageData] = useState("");
  const [dataUri, setDataUri] = useState({});
  const [attachedFilesData, setAttachedFilesData] = useState();
  const [goals, setGoals] = useState({
    More_sales: false,
    Brand_awareness: false,
    Better_online_presence: false,
    All_of_the_above: false,
  });
  const [radioSelected, setRadioSelected] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();
  // -----use form login validation ----
  const {
    register,
    watch,
    setValue,
    handleSubmit,
    trigger,
    control,
    getValues,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  // --- * radio option
  const handleChange = (e) => {
    const target = e.target;
    if (target.checked) {
      setRadioSelected(+target.value);
    }
  };
  // ---- -------* listing api *------------
  const userListingApi = (async) => {
    setSpinner(true);
    try {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/api/admin/users/${id}`, {
          headers: headers,
        })
        ?.then((res) => {
          if (res?.status >= 200) {
            setUserDetails(res?.data?.data);
            console.log("ressssssssssss", res.data.data.email);
            setValue("email", res?.data?.data?.email);
            // ----- *image ---
            if (res?.data?.data?.business_info?.attachments.length > 0) {
              setDataUri({
                img: `${
                  !res?.data?.data?.business_info?.attachments[0]?.file ||
                  res?.data?.data?.business_info === null
                    ? "./assets/images/upload-thumb.svg"
                    : `https://api.thrivedigitaly.com/storage/${res?.data?.data?.business_info?.attachments[0]?.file}`
                }`,
                url: `${
                  !res?.data?.data?.business_info?.attachments[0]?.file ||
                  res?.data?.data?.business_info === null
                    ? "./assets/images/upload-thumb.svg"
                    : `https://api.thrivedigitaly.com/storage/${res?.data?.data?.business_info?.attachments[0]?.file}`
                }`,
              });
            }
            setImageData(res?.data?.data?.business_info?.attachments[0]?.file);
            setRadioSelected(res?.data?.data?.business_info?.website);
            // ------ *gols check boxe ---
            setGoals({
              More_sales:
                res?.data?.data?.business_info?.goals.includes("More sales"),
              Brand_awareness:
                res?.data?.data?.business_info?.goals.includes(
                  "Brand awareness"
                ),
              Better_online_presence:
                res?.data?.data?.business_info?.goals.includes(
                  "Better online presence"
                ),
              All_of_the_above:
                res?.data?.data?.business_info?.goals.includes(
                  "All of the above"
                ),
            });
            // ------- * get key value pair from a object & object into object
            // @Object.entries(res?.data?.data?.business_info)
            for (const [key, value] of Object.entries(
              res?.data?.data?.business_info
            )) {
              setValue(key, value);
            }
            for (const [key, value] of Object.entries(res?.data?.data)) {
              setValue(key, value);
            }
            toast.success(res?.data?.message);
            setSpinner(false);
          }
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message);
          setSpinner(false);
        });
    } catch (error) {}
  };
  // ---- -------*save and update user api *------------
  const userUpdateApi = async (data) => {
    console.log("data", data);
    setSpinner(true);
    let body = {
      name: watch("name"),
      email: watch("email"),
      phone_number: watch("phone_number"),
      business_name: watch("business_name"),
      business_address: watch("business_address"),
      business_phone: watch("business_phone"),
      ...(radioSelected === 1 ? { website_url: watch("website_url") } : ""),
      create_a_website: 0,
      // ...(watch("speciality") ? { speciality: watch("speciality") } : ""),
      // ...(watch("uniqueness") ? { uniqueness: watch("uniqueness") } : ""),
      // ...(watch("product_services")
      //   ? { product_services: watch("product_services") }
      //   : ""),
      speciality: watch("speciality"),
      uniqueness: watch("uniqueness"),
      product_services: watch("product_services"),
      goals: [
        ...(goals?.More_sales ? ["More sales"] : ""),
        ...(goals?.Brand_awareness ? ["Brand awareness"] : ""),
        ...(goals?.Better_online_presence ? ["Better online presence"] : ""),
        ...(goals?.All_of_the_above ? ["All of the above"] : ""),
      ],
      ...(imageData ? { attachments: [imageData] } : ""),
      // attachments: [imageData],
      website: radioSelected ? radioSelected : 0,
    };

    console.log("crw", watch("create_a_website"));
    console.log("wesbite", radioSelected);
    console.log("url", watch("website_url"));

    try {
      axios
        .put(`${process.env.REACT_APP_BASE_URL}/api/admin/users/${id}`, body, {
          headers: headers,
        })
        ?.then((res) => {
          if (res?.status >= 200) {
            userListingApi();
            toast.success(res?.data?.message);
            setSpinner(false);
            navigate("/users");
          }
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message);
          setSpinner(false);
        });
    } catch (error) {}
  };
  // ---- -------*update image*------------
  const onChangeAttachedFilesData = (e) => {
    const data = e.target.files;

    const reader = new FileReader();

    reader.onload = (loadEvt) => {
      setDataUri({ img: data[0], url: loadEvt.target.result });
    };
    // reader.readAsDataURL(data[0]);

    for (let elem of data) {
      setAttachedFilesData({
        name: elem.name,
        file: elem,
        url: `https://api.thrivedigitaly.com/storage/${elem?.name}`,
      });
      setSpinner(true);

      const headers = {
        Accept: "multipart/form-data",
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("useName"))?.token
        }`,
      };
      let formData = new FormData();
      formData.append("image", elem);

      setTimeout(() => {
        axios({
          method: "POST",
          url: `https://api.thrivedigitaly.com/api/upload-image`,
          data: formData,
          headers: headers,
        })
          .then((success) => {
            setSpinner(false);
            setImageData(success?.data?.image);
            setDataUri((prev) => ({
              ...prev,
              url: `https://api.thrivedigitaly.com/storage/${success?.data?.image}`,
            }));
          })
          .catch((error) => {
            setSpinner(false);
          });
      }, 2000);
    }
  };
  // submit form
  const formSubmit = (data) => {
    userUpdateApi(data);
  };
  useEffect(() => {
    userListingApi();
  }, []);

  const handleFileReset = (file) => {
    setValue("dataUri", "");
    setDataUri("");
    setPreviewImage("");
    setFileS("");
  };

  const [previewImage, setPreviewImage] = useState(null);
  const [fileS, setFileS] = useState();

  const [errorGoals, setErrorGoals] = useState(false);
  useEffect(() => {
    function areAllPropertiesFalse(obj) {
      for (const key in obj) {
        if (obj[key] === true) {
          return false; // If any property is true, return false
        }
      }
      return true; // If all properties are false, return true
    }

    const allPropertiesFalse = areAllPropertiesFalse(goals);
    console.log("goals", allPropertiesFalse);
    setErrorGoals(allPropertiesFalse);
    // trigger("More_sales");
    // trigger("Brand_awareness");
    // trigger("Better_online_presence");
    // trigger("All_of_the_above");

    //   trigger("More_sales");
  }, [goals, trigger]);

  const a1 = watch("Brand_awareness");
  const a2 = watch("Better_online_presence");
  const a3 = watch("More_sales");

  const allGoals = watch("All_of_the_above");
  console.log("aaaaaaaaaaaaaa", goals);
  useEffect(() => {
    if (allGoals) {
      setGoals({
        More_sales: true,
        Brand_awareness: true,
        Better_online_presence: true,
        All_of_the_above: true,
      });
    }
  }, [allGoals, a1, a2, a3, setValue]);
  console.log(a1, a2, a3, allGoals);

  useEffect(() => {
    if (
      goals.More_sales === false ||
      goals.Brand_awareness === false ||
      goals.Better_online_presence === false
    ) {
      setGoals((prevGoals) => ({
        ...prevGoals,
        All_of_the_above: false,
      }));
      setValue("All_of_the_above", false);
    }
  }, [
    goals.Better_online_presence,
    goals.Brand_awareness,
    goals.More_sales,
    setValue,
  ]);

  return (
    <div className="edit-user inner-form">
      <form onSubmit={handleSubmit(userUpdateApi)}>
        <div className="title d-flex justify-content-between align-items-center text-capitalize">
          <h2>
            <BackNavigate /> Edit User Details
          </h2>
          {/* {userDetails?.name ? `${userDetails?.name}'s` : ""} */}

          <div className="d-flex search-dropdown">
            <Link
              to={`/users/user-post/${id}`}
              state={{ from: id }}
              className="primery-btn"
            >
              Posts
            </Link>
          </div>
        </div>
        <div className="content">
          {/* -------- * package details  */}

          {userDetails?.subscription?.package === "gold" && (
            <div className="package-detail">
              <div className="inner-title">
                <h3>
                  Package Details
                  {/* <img src="./assets/images/edit2.svg" alt="" /> */}
                </h3>
              </div>

              <div className="gold-plan d-flex">
                <p>Gold Plan</p>
                <span>
                  <sup>$</sup> <b>{userDetails?.subscription?.amount}</b>
                </span>
              </div>
            </div>
          )}
          {userDetails?.subscription?.package === "platinum" && (
            <div className="package-detail">
              <div className="inner-title">
                <h3>
                  Package Details
                  {/* <img src="./assets/images/edit2.svg" alt="" /> */}
                </h3>
              </div>

              <div className="platinum-plan d-flex">
                <p>Platinum Plan</p>
                <span>
                  <sup>$</sup> <b>{userDetails?.subscription?.amount}</b>
                </span>
              </div>
            </div>
          )}
          {userDetails?.subscription?.package === "silver" && (
            <div className="package-detail">
              <div className="inner-title">
                <h3>
                  Package Details
                  {/* <img src="./assets/images/edit2.svg" alt="" /> */}
                </h3>
              </div>

              <div className="silver-plan d-flex">
                <p>Silver Plan</p>
                <span>
                  <sup>$</sup> <b>{userDetails?.subscription?.amount}</b>
                </span>
              </div>
            </div>
          )}
          {/* ------------- Profile Info  */}
          <div className="user-profile-info">
            <div className="inner-title">
              <h3>
                Profile Info
                {/* <img src="./assets/images/edit2.svg" alt="" /> */}
              </h3>
            </div>
            <div className="user-social-post">
              <div className="social-box">
                <label>
                  <b>
                    Full Name &nbsp;{" "}
                    <span className="text-danger">
                      <sup>*</sup>
                    </span>
                  </b>
                </label>
                <div className="form-group w-100">
                  <CommonInput
                    id="fullName"
                    type="text"
                    label=""
                    inputClass={`${errors.name ? " border-danger" : " "}`}
                    register={{
                      ...register("name", {
                        required: true,
                      }),
                    }}
                  />
                  {errors.name?.type === "required" && (
                    <p role="alert" className="alert-msg text-danger">
                      Full Name is required.
                    </p>
                  )}
                </div>
              </div>
              <div className="social-box">
                <label>
                  <b>
                    Email &nbsp;{" "}
                    <span className="text-danger">
                      <sup>*</sup>
                    </span>{" "}
                  </b>
                </label>
                <div className="form-group w-100">
                  <CommonInput
                    id="email"
                    type="email"
                    label=""
                    placeholder="Enter email"
                    inputClass={`${errors.email ? " border-danger" : ""}`}
                    register={{
                      ...register("email", {
                        required: true,
                        pattern: /\S+@\S+\.\S+/,
                      }),
                    }}
                  />
                  {errors.email?.type === "required" && (
                    <p role="alert" className="alert-msg text-danger">
                      Email is required.
                    </p>
                  )}
                  {errors.email?.type === "pattern" && (
                    <p role="alert" className="text-danger">
                      Incorrect email format
                    </p>
                  )}
                </div>
              </div>
              <div className="social-box">
                <label>
                  <b>
                    Phone&nbsp;{" "}
                    <span className="text-danger">
                      <sup>*</sup>
                    </span>
                  </b>
                </label>
                <div className="form-group w-100">
                  {/* <CommonInput
                    id="phone"
                    type="text"
                    label=""
                    maxLength={12}
                    minLength={12}
                    inputClass={`${
                      errors.phone_number ? " border-danger" : " "
                    }`}
                    onKeyPress={(event) => {
                      if (!/[0-9+() ]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    register={{
                      ...register("phone_number", {
                        required: "Phone Number is required",
                        // pattern: {
                        //   value: /^[0-9]{12}$/,
                        //   message: "Please enter a valid phone number",
                        // },
                        minLength: {
                          value: 12,
                          message: "Phone no. must be 12 digits",
                        },
                        maxLength: {
                          value: 12,
                          message: "Phone no. must be 12 digits",
                        },
                      }),
                    }}
                  /> */}
                  <Controller
                    name="phone_number" // Make sure the name matches the form field name
                    control={control}
                    defaultValue={getValues("phone_number")} // Assuming getValues returns a string
                    rules={{
                      required: "Phone number is required",
                      validate: (value) => {
                        const phoneNumber = value.replace(/[^\d]/g, ""); // Remove non-digit characters
                        return (
                          phoneNumber.length >= 10 ||
                          "Phone number must be 10 digits to 15 digits"
                        );
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <div>
                        <PhoneInput
                          autoFormat
                          enableSearch
                          country={"us"}
                          value={
                            getValues("phone_number") &&
                            getValues("phone_number").length >= 1
                              ? getValues("phone_number")
                              : field.value
                          }
                          onChange={(value) => {
                            field.onChange(value);
                            if (value.length < 1) {
                              setValue("phone_number", ""); // Set the phone_number field to empty
                            }
                          }}
                        />
                        {fieldState.error && (
                          <span className="text-danger">
                            {fieldState.error.message}
                          </span>
                        )}
                      </div>
                    )}
                  />

                  {/* {errors.phone_number && (
                    <span style={{ color: "red" }}>
                      {errors.phone_number.message}
                    </span>
                  )} */}
                </div>
              </div>
            </div>
          </div>
          {/* ------- Business Info  */}
          <div className="user-business-info">
            <div className="inner-title">
              <h3>
                Business Info
                {/* <img src="./assets/images/edit2.svg" alt="" /> */}
              </h3>
            </div>
            <div className="user-social-post">
              <div className="social-box">
                <label>
                  <b>
                    Business Name&nbsp;{" "}
                    <span className="text-danger">
                      <sup>*</sup>
                    </span>
                  </b>
                </label>
                <div className="form-group w-100">
                  <CommonInput
                    id="business_name"
                    type="text"
                    inputClass={`${
                      errors.business_name ? " border-danger" : " "
                    }`}
                    label=""
                    register={{
                      ...register("business_name", {
                        required: true,
                      }),
                    }}
                  />
                  {errors.business_name?.type === "required" && (
                    <p role="alert" className="alert-msg text-danger">
                      Business Name is required.
                    </p>
                  )}
                </div>
              </div>
              <div className="social-box">
                <label>
                  <b>
                    Business Phone&nbsp;{" "}
                    <span className="text-danger">
                      <sup>*</sup>
                    </span>
                  </b>
                </label>
                <div className="form-group w-100">
                  {/* <CommonInput
                    id="business_phone"
                    type="text"
                    label=""
                    maxLength={12}
                    onKeyPress={(event) => {
                      if (!/[0-9+() ]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    inputClass={`${
                      errors.business_phone ? " border-danger" : " "
                    }`}
                    register={{
                      ...register("business_phone", {
                        required: "Phone Number is required",
                        // pattern: {
                        //   value: /^[0-9]{12}$/,
                        //   message: "Please enter a valid phone number",
                        // },
                        minLength: {
                          value: 12,
                          message: "Phone no. must be 12 digits",
                        },
                        maxLength: {
                          value: 12,
                          message: "Phone no. must be 12 digits",
                        },
                      }),
                    }}
                  /> */}
                  <Controller
                    name="business_phone" // Make sure the name matches the form field name
                    control={control}
                    defaultValue={getValues("business_phone")} // Assuming getValues returns a string
                    rules={{
                      required: "Phone number is required",
                      validate: (value) => {
                        const phoneNumber = value.replace(/[^\d]/g, ""); // Remove non-digit characters
                        return (
                          phoneNumber.length >= 10 ||
                          "Phone number must be 10 digits to 15 digits"
                        );
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <div>
                        <PhoneInput
                          autoFormat
                          enableSearch
                          country={"us"}
                          value={
                            getValues("business_phone") &&
                            getValues("business_phone").length >= 1
                              ? getValues("business_phone")
                              : field.value
                          }
                          onChange={(value) => {
                            field.onChange(value);
                            if (value.length < 1) {
                              setValue("business_phone", ""); // Set the phone_number field to empty
                            }
                          }}
                        />
                        {fieldState.error && (
                          <span className="text-danger">
                            {fieldState.error.message}
                          </span>
                        )}
                      </div>
                    )}
                  />
                  {/* {errors.business_phone && (
                    <span style={{ color: "red" }}>
                      {errors.business_phone.message}
                    </span>
                  )} */}
                </div>
              </div>
              <div className="social-box">
                <label>
                  <b>
                    Business Address&nbsp;{" "}
                    <span className="text-danger">
                      <sup>*</sup>
                    </span>
                  </b>
                </label>
                <div className="form-group w-100">
                  <CommonInput
                    id="business_address"
                    type="text"
                    label=""
                    inputClass={`${
                      errors.business_address ? " border-danger" : " "
                    }`}
                    register={{
                      ...register("business_address", {
                        required: true,
                      }),
                    }}
                  />
                  {errors.business_address?.type === "required" && (
                    <p role="alert" className="alert-msg text-danger">
                      Business address is required.
                    </p>
                  )}
                </div>
              </div>
              <div className="social-box">
                <label>
                  <b>
                    Do you have a website?&nbsp;
                    {/* <span className="text-danger"><sup>*</sup></span> */}
                  </b>
                </label>
                <div className="form-group w-100 d-flex">
                  <div className="checkbox reverse-label custom-checkbox me-5">
                    <label htmlFor={id} className="pointer">
                      Yes
                    </label>
                    <input
                      type="radio"
                      value={1}
                      className="checkbox reverse-label"
                      onChange={handleChange}
                      checked={radioSelected == 1}
                    />
                    {/* <CommonInput
                                            type="radio"
                                            label="Yes"
                                            className="me-5 checkbox reverse-label"
                                            value={1}
                                            checkBox
                                            // checked={getValues("website") === 1 ? true : false}
                                            checked={radioSelected == 1}
                                            onChange={handleChange}
                                        // onChange={(e)=>setWebsiteOptionYes(prev=>({...prev,website:e.target.checked}))}
                                        /> */}
                  </div>
                  <div className="checkbox reverse-label custom-checkbox">
                    <label htmlFor={id} className="pointer">
                      No
                    </label>
                    <input
                      type="radio"
                      value={0}
                      checked={radioSelected == 0}
                      // checked={radioSelected2 === 1 ? false : true}
                      className="checkbox reverse-label"
                      onChange={handleChange}
                    />
                  </div>
                  {/* <CommonInput type="radio"
                                        label="No"
                                        className="me-2 checkbox reverse-label"
                                        checkBox
                                        value={0}
                                        // onChange={(e)=>setWebsiteOptionNo(prev=>({...prev,website:e.target.checked}))}
                                        // checked={getValues("website") === 0 ? true : false}
                                        checked={radioSelected == 1}
                                        onChange={handleChange}

                                    /> */}
                </div>
              </div>
              {radioSelected === 1 && (
                <div className="social-box">
                  <label>
                    <b>
                      Business Website&nbsp;
                      {radioSelected === 1 && (
                        <span className="text-danger">
                          <sup>*</sup>
                        </span>
                      )}
                    </b>
                  </label>
                  <div className="form-group w-100">
                    <CommonInput
                      id="website_url"
                      type="text"
                      label=""
                      placeholder="https://thrivedigitaly.com"
                      inputClass={
                        radioSelected === 1
                          ? `${errors.website_url ? " border-danger" : " "}`
                          : ""
                      }
                      register={{
                        ...register("website_url", {
                          required: "Company Website is required",
                          pattern: {
                            value:
                              /^(ftp|http|https):\/\/[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+(\.[a-zA-Z]{2,})?(:[0-9]{1,5})?(\/.*)?$/,
                            message: "Please enter a valid website URL",
                          },
                        }),
                      }}
                      className={radioSelected === 0 ? "disabled pe-none" : ""}
                      disable={radioSelected === 0 ? true : ""}
                    />
                    {radioSelected === 1
                      ? errors.website_url && (
                          <span style={{ color: "red" }}>
                            {errors.website_url.message}
                          </span>
                        )
                      : ""}
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* ---- * Business Details */}
          <div className="user-business-details">
            <div className="inner-title">
              <h3>
                Business Details
                {/* <img src="./assets/images/edit2.svg" alt="" /> */}
              </h3>
            </div>
            <div className="user-social-post">
              <div className="bus-con ">
                <label>
                  <b>
                    Every business is special in its own way. Please tell us
                    yours speciality.&nbsp;{" "}
                    <span className="text-danger">
                      <sup></sup>
                    </span>
                  </b>
                </label>
                <div className="form-group w-100">
                  <CommonInput
                    id="speciality"
                    type="text"
                    label=""
                    inputClass={`${errors.speciality ? " border-danger" : " "}`}
                    register={{
                      ...register("speciality"),
                    }}
                  />
                  {errors.speciality?.type === "required" && (
                    <p role="alert" className="alert-msg text-danger">
                      Business speciality is required.
                    </p>
                  )}
                </div>
              </div>
              <div className="bus-con">
                <label>
                  <b>
                    What is unique about your business that will make your
                    business stand up in crowd.&nbsp;{" "}
                    <span className="text-danger">
                      <sup></sup>
                    </span>
                  </b>
                </label>
                <div className="form-group w-100">
                  <CommonInput
                    id="uniqueness"
                    type="text"
                    label=""
                    inputClass={`${errors.uniqueness ? " border-danger" : " "}`}
                    register={{
                      ...register("uniqueness"),
                    }}
                  />
                  {errors.uniqueness?.type === "required" && (
                    <p role="alert" className="alert-msg text-danger">
                      Business uniqueness is required.
                    </p>
                  )}
                </div>
              </div>
              <div className="bus-con">
                <label>
                  <b>
                    Please tell us about your products and services.&nbsp;{" "}
                    <span className="text-danger">
                      <sup></sup>
                    </span>{" "}
                  </b>
                </label>

                <div className="form-group w-100">
                  <CommonInput
                    id="uniqueness"
                    type="text"
                    label=""
                    inputClass={`${
                      errors.product_services ? " border-danger" : " "
                    }`}
                    register={{
                      ...register("product_services"),
                    }}
                  />
                  {errors.product_services?.type === "required" && (
                    <p role="alert" className="alert-msg text-danger">
                      Product & services is required.
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* -------- Business Other Details  */}
          <div className="other-user-business-details">
            <div className="inner-title">
              <h3>
                Business Other Details
                {/* <img src="./assets/images/edit2.svg" alt="" /> */}
              </h3>
            </div>
            <div className="user-social-post border-0 mb-0">
              <div className="bus-con">
                <label>
                  <b>
                    What is your main goal? &nbsp;{" "}
                    <span className="text-danger">
                      <sup>*</sup>
                    </span>
                  </b>
                </label>

                <div className="main-goal ">
                  <div className={`form-group w-100`}>
                    <CommonInput
                      type="checkbox"
                      label="More sales"
                      className="me-2 checkbox reverse-label"
                      id="gole01"
                      //  inputClass={errors.More_sales ? " border-danger" : ""}
                      checkBox
                      checked={goals?.More_sales}
                      register={{
                        ...register("More_sales", {
                          required: errorGoals ? "Goals are required" : false,
                          onChange: () =>
                            setGoals((prev) => ({
                              ...prev,
                              More_sales: !goals.More_sales,
                            })),
                        }),
                      }}
                    />
                  </div>
                  <div className="form-group w-100">
                    <CommonInput
                      type="checkbox"
                      label="Brand awareness"
                      className="me-2 checkbox reverse-label"
                      id="gole02"
                      checkBox
                      // inputClass={
                      //   errors.Brand_awareness ? " border-danger" : ""
                      // }
                      checked={goals?.Brand_awareness}
                      // onChange={(e) => setGoals(prev => ({ ...prev, Brand_awareness: e.target.checked }))}
                      register={{
                        ...register("Brand_awareness", {
                          required: errorGoals ? "Goals are required" : false,
                          onChange: () =>
                            setGoals((prev) => ({
                              ...prev,
                              Brand_awareness: !goals.Brand_awareness,
                            })),
                        }),
                      }}
                    />
                  </div>
                  <div className="form-group w-100">
                    <CommonInput
                      type="checkbox"
                      label="Better online presence"
                      className="me-2 checkbox reverse-label"
                      id="gole03"
                      checkBox
                      // inputClass={
                      //   errors.Better_online_presence ? " border-danger" : ""
                      // }
                      checked={goals?.Better_online_presence}
                      // onChange={(e) => setGoals(prev => ({ ...prev, Better_online_presence: e.target.checked }))}
                      register={{
                        ...register("Better_online_presence", {
                          required: errorGoals ? "Goals are required" : false,
                          onChange: () =>
                            setGoals((prev) => ({
                              ...prev,
                              Better_online_presence:
                                !goals.Better_online_presence,
                            })),
                        }),
                      }}
                    />
                  </div>
                  <div className="form-group w-100">
                    <CommonInput
                      type="checkbox"
                      label="All of the above"
                      // inputClass={
                      //   errors.All_of_the_above ? " border-danger" : ""
                      // }
                      className="me-2 checkbox reverse-label"
                      id="gole04"
                      checkBox
                      checked={goals?.All_of_the_above}
                      // onChange={(e) => setGoals(prev => ({ ...prev, All_of_the_above: e.target.checked }))}
                      register={{
                        ...register("All_of_the_above", {
                          required: errorGoals ? "Goals are required" : false,
                          onChange: () =>
                            setGoals((prev) => ({
                              ...prev,
                              All_of_the_above: !goals.All_of_the_above,
                            })),
                        }),
                      }}
                    />
                  </div>
                </div>
                {/* {(errors?.More_sales ||
                  errors?.Brand_awareness ||
                  errors?.Better_online_presence ||
                  errors?.All_of_the_above) && (
                  <span style={{ color: "red" }}>
                    {errors?.More_sales?.message}
                  </span>
                )} */}

                {errorGoals && (
                  <span style={{ color: "red" }}>{"Goals are required"}</span>
                )}

                {/* <ul>
                                {userDetails?.business_info?.goals?.map((item, index) => {
                                    return (
                                        <li>{item}</li>
                                    )
                                })
                                }
                            </ul>  */}
              </div>
              <div className="bus-con">
                <label>
                  <b>
                    Would you like to share photos and logos of your
                    business&nbsp;{" "}
                    {/* <span className="text-danger">
                      <sup>*</sup>
                    </span> */}
                  </b>
                </label>
                <div className="form-group mt-4">
                  {/* <input {...register("dataUri",  {onChange:(e) => {
                                                if (e.target.files[0]?.size > 1e6) {
                                                    toast.error("Image size too large,upload upto 1MB.", {
                                                        autoClose: 3000,
                                                    });
                                                    return;
                                                } else {
                                                    onChangeAttachedFilesData(e);
                                                }

                                            },required: true })}
                                            // accept="image/*"
                                            
                                        type="file" /> */}
                  <div className="set-cross-icon">
                    <label
                      htmlFor="upload"
                      className={`upload_file ${
                        errors.dataUri ? " border-danger" : ""
                      }`}
                    >
                      <input
                        accept=".jpg,.jpeg,.png"
                        type="file"
                        id="upload"
                        className="d-none"
                        {...register("dataUri", {
                          onChange: (e) => {
                            const file = e.target.files[0];
                            setFileS(file);

                            setPreviewImage(URL.createObjectURL(file));
                            if (e.target.files[0]?.size > 1e6) {
                              toast.error(
                                "Image size too large,upload upto 1MB.",
                                {
                                  autoClose: 3000,
                                }
                              );
                              return;
                            } else {
                              onChangeAttachedFilesData(e);
                            }
                          },
                          // required:
                          //   !dataUri?.url && !previewImage
                          //     ? "Image is required"
                          //     : false,
                          validate: (value) => {
                            if (value[0]) {
                              const allowedTypes = [
                                "image/jpeg",
                                "image/png",
                                "image/jpg",
                              ];
                              if (!allowedTypes.includes(value[0].type)) {
                                setValue("dataUri", "");
                                return "Only images (JPEG, PNG) are allowed";
                              }
                              if (value[0].size > MAX_IMAGE_SIZE) {
                                return "Image size should not exceed 1MB";
                              }
                              return true;
                            }
                          },
                        })}

                        // onChange={(e) => {
                        //     if (e.target.files[0]?.size > 1e6) {
                        //         toast.error("Image size too large,upload upto 1MB.", {
                        //             autoClose: 3000,
                        //         });
                        //         return;
                        //     } else {
                        //         onChangeAttachedFilesData(e);
                        //     }

                        // }}
                      />

                      <>
                        {(dataUri?.img || previewImage) && (
                          <img
                            src={dataUri.url ? dataUri?.url : previewImage}
                            alt="userImg"
                          />
                        )}
                        {!dataUri?.img && !previewImage && (
                          <img
                            src="./assets/images/upload-thumb.svg"
                            alt="profile"
                            style={{ cursor: "pointer" }}
                          />
                        )}
                      </>
                    </label>

                    {(dataUri?.img || previewImage) && (
                      <span
                        className="cross"
                        onClick={() => {
                          handleFileReset("image");
                        }}
                      >
                        <img src="assets/images/cross.svg" alt="" />
                      </span>
                    )}
                  </div>
                  {errors.dataUri && (
                    <span style={{ color: "red" }}>
                      {errors.dataUri.message}
                    </span>
                  )}
                  {/* {errors.More_sales && (
                    <span style={{ color: "red" }}>
                      {errors.More_sales.message}
                    </span>
                  )} */}
                </div>
              </div>
              {/* <div className="bus-con">
                            <label><b>Products descriptions</b></label>
                            <p>{userDetails?.business_info?.product_descriptions ? userDetails?.business_info?.product_descriptions : <span className="empty-data gray-text">-</span>}</p>
                        </div> */}
            </div>
          </div>

          <button type="submit" className="primery-btn mt-4">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}
