import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import axios, { headers } from "../../http/axios";
import { GlobalContext } from "../../App";
import { toast } from "react-toastify";
import Pagination from "../common-Component/Pagination";
import CommonModal from "../common-Component/modal/CommonModal";
import { debounce } from "../../helpers/debouncing";

export function Blogs() {
  const [blogData, setBlogData] = useState();
  const { setSpinner } = useContext(GlobalContext);
  const [paginationData, setPaginationData] = useState();
  const [pages, setPages] = useState(1);
  const [pageRefresh, setpageRefresh] = useState(false);
  const [search, setSearch] = useState("");
  const [testDelete, setTestDelete] = useState({
    state: false,
    id: "",
  });

  useEffect(() => {
    getBlogData();
  }, [pages, pageRefresh]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      // Call your search function here
      getBlogData();
    }, 500); // Delay time in milliseconds

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [search]);

  const getBlogData = () => {
    setSpinner(true);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/admin/blogs?page=${pages}&size=10&search=${search}`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        console.log("response ", response.data.data);
        setPaginationData(response.data.data);
        setBlogData(response.data.data.data);
      })
      .finally(() => {
        setSpinner(false);
      });
  };
  const handleDeleteBlog = (blogId) => {
    axios
      .delete(`${process.env.REACT_APP_BASE_URL}/api/admin/blogs/${blogId}`, {
        headers: headers,
      })
      .then((response) => {
        toast.success("Blog deleted ,Successfully!");
      })
      .finally(() => {
        setSpinner(false);
        setpageRefresh(!pageRefresh);
        setTestDelete({ state: false });
      });
  };

  function trimText(htmlContent) {
    if (!htmlContent) {
      return { __html: "" }; // Return an empty string if the input is null or undefined
    }

    // Create a temporary div element to parse and sanitize the HTML content
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlContent;

    // Get the text content without any HTML tags
    const textContent = tempDiv.textContent;

    // Trim the text to a maximum of 100 characters
    const trimmedText =
      textContent.length > 100 ? `${textContent.substring(0, 100)} ...` : textContent;

    return { __html: trimmedText };
  }

  return (
    <>
      <div className="title d-flex justify-content-between align-items-center">
        <h2>Blogs</h2>
        <div className="d-flex search-dropdown">
          <div className="search-box">
            <img src="./assets/images/search.svg" alt="" />
            <input
              type="search"
              placeholder="Search Blog"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          </div>
          <Link to="/blogs/add-blog" className="primery-btn">
            <img src="./assets/images/plus.svg" alt="" /> Add{" "}
          </Link>
        </div>
      </div>
      <div className="content">
        <div className="responsive-table">
          <table>
            <thead>
              <tr>
                <th width="6%">S.No.</th>
                <th width="22%">Title</th>
                <th width="40%">Content</th>
                <th width="10%">Banner Image</th>
                <th width="12%">Thumbnail Image</th>
                <th width="10%">Action</th>
              </tr>
            </thead>
            <tbody>
              {blogData?.length > 0 &&
                blogData?.map((ele, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{ele?.title}</td>
                      {/* <td >
                        {ele?.content ? (
                          ele?.content.length > 100 ? (
                            `${ele?.content?.substring(0, 100)} ...`
                          ) : (
                            ele?.content
                          )
                        ) : (
                          <span className="empty-data">-</span>
                        )}
                      </td> */}
                      <td>
                        <div dangerouslySetInnerHTML={{ __html: ele?.content ? (
                          ele?.content.length > 100 ? (
                            `${ele?.content.trim()?.substring(0, 100)} ...`
                          ) : (
                            ele?.content
                          )
                        ) : (
                          <span className="empty-data">-</span>
                        ) }} />
                      </td>

                      <td>
                        <img
                          src={`${process.env.REACT_APP_MEDIA_URL}/${ele?.banner_image}`}
                          alt="banner_image"
                          className="banner-img"
                        />
                      </td>
                      <td>
                        <img
                          src={`${process.env.REACT_APP_MEDIA_URL}/${ele?.thumbnail_image}`}
                          alt=" "
                          className="banner-img"
                        />
                      </td>
                      <td>
                        <Link to={"/blogs/blog-details/" + ele?.id}>
                          <img src="./assets/images/eye.svg" alt="" className="edit" />
                        </Link>
                        <Link to={"/blogs/edit-blog/" + ele?.id}>
                          <img src="./assets/images/edit.svg" alt="" className="edit" />
                        </Link>

                        <img
                          src="./assets/images/trash.svg"
                          alt=" "
                          className="edit"
                          // onClick={() => setTestDelete(ele?.id)}
                          onClick={() => setTestDelete({ state: true, id: ele?.id })}
                        />
                      </td>
                    </tr>
                  );
                })}
              {blogData?.length === 0 && (
                <div className="alert-text no-data">
                  <div className="error-data">
                    <p className="font-size-1.5"> No data found</p>
                  </div>
                </div>
              )}
            </tbody>
          </table>
          {blogData?.length > 0 && (
            <div className="pagination">
              {/* <img src="./assets/images/pagination.png" alt="" /> */}
              <Pagination
                totalPages={paginationData?.last_page}
                currentPage={paginationData?.current_page}
                page={pages}
                setPage={(page) => setPages(page)}
              />
            </div>
          )}
        </div>
      </div>
      <CommonModal
        show={testDelete.state}
        onHide={() =>
          setTestDelete({
            state: false,
          })
        }
        className="add-post-modal"
        header={<></>}
        body={
          <>
            <h3>Delete Blog</h3>
            <p>Are you sure you want to delete this Blog?</p>
            <div className="btnContainer-modal d-flex">
              <button className="primery-btn me-3" onClick={() => handleDeleteBlog(testDelete?.id)}>
                Yes
              </button>
              <button
                className="secondry-btn"
                onClick={() =>
                  setTestDelete({
                    state: false,
                  })
                }
              >
                No
              </button>
            </div>
          </>
        }
      />
    </>
  );
}
