// import React from "react";
// import CommonInput from "../common-Component/form/CommonInput";
// import { useForm } from "react-hook-form";
// import axios, { headers } from "../../http/axios";
// import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";

// export function ChangePassword() {
//   const navigate = useNavigate();
//   const {
//     register,
//     handleSubmit,
//     formState: { errors },
//     watch,
//   } = useForm({ mode: "onChange" });

//   console.log(headers);

//   const onSubmit = async (data) => {
//     console.log(data); // Access form data here
//     // Additional logic for submitting the form

//     var config = {
//       method: "post",
//       url: `${process.env.REACT_APP_BASE_URL}/api/admin/change-password?old_password=${data?.oldPassword}&password=${data?.newPassword}&password_confirmation=${data?.confirmPassword}`,
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//         Authorization: headers.Authorization,
//       },
//     };

//     axios(config)
//       .then(function (response) {
//         toast.success(response.data.message + ",Please Log in again");
//         localStorage.clear();
//         navigate("/");
//         console.log(JSON.stringify(response.data));
//       })
//       .catch(function (error) {
//         toast.error(error.response.data.message);
//       });
//   };

//   const newPassword = watch("newPassword");
//   const confirmPassword = watch("confirmPassword");
//   const passwordMatch = newPassword === confirmPassword;

//   return (
//     <>
//       <div className="title d-flex justify-content-between align-items-center">
//         <h2>Change Password</h2>
//       </div>
//       <div className="content">
//         <form
//           className="form"
//           style={{ maxWidth: "350px" }}
//           onSubmit={handleSubmit(onSubmit)}
//         >
//           <div className="form-group">
//             <input
//               type="password"
//               placeholder="Old Password"
//               label="Old Password"
//               {...register("oldPassword", {
//                 required: "Old Password is required",
//                 validate: (value) =>
//                   value.trim().length > 0 || "Old Password is required",
//               })}
//             />
//             {errors.oldPassword && (
//               <span style={{ color: "red" }}>{errors.oldPassword.message}</span>
//             )}
//           </div>
//           <div className="form-group">
//             <input
//               type="password"
//               placeholder="Enter new password"
//               label="New Password"
//               {...register("newPassword", {
//                 required: "New Password is required",
//                 validate: (value) =>
//                   value.trim().length > 0 || "New Password is required",
//                 minLength: (value) =>
//                   value.trim().length < 8 ||
//                   "New Password should be at least 8 characters long",
//               })}
//             />
//             {errors.newPassword?.type === "validate" && (
//               <span style={{ color: "red" }}>New Password is required</span>
//             )}
//             {errors.newPassword?.type === "required" && (
//               <span style={{ color: "red" }}>New Password is required</span>
//             )}
//             {errors.newPassword?.type === "minLength" && (
//               <span style={{ color: "red" }}>
//                 New Password should be at least 8 characters long
//               </span>
//             )}
//           </div>
//           <div className="form-group">
//             <input
//               type="password"
//               placeholder="Enter confirm password"
//               label="Confirm Password"
//               {...register("confirmPassword", { required: true })}
//             />
//             {errors.confirmPassword && (
//               <span style={{ color: "red" }}>Confirm Password is required</span>
//             )}
//             {!passwordMatch && (
//               <span style={{ color: "red" }}>Passwords do not match</span>
//             )}
//           </div>
//           <div className="form-group">
//             <button className="primery-btn">Submit</button>
//           </div>
//         </form>
//       </div>
//     </>
//   );
// }

import React from "react";
import CommonInput from "../common-Component/form/CommonInput";
import { useForm } from "react-hook-form";
import axios, { headers } from "../../http/axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export function ChangePassword() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({ mode: "onChange" });

  console.log(headers);

  const onSubmit = async (data) => {
    console.log(data); // Access form data here
    // Additional logic for submitting the form

    var config = {
      method: "post",
      url: `${
        process.env.REACT_APP_BASE_URL
      }/api/admin/change-password?old_password=${data?.oldPassword?.trim()}&password=${data?.newPassword?.trim()}&password_confirmation=${data?.confirmPassword?.trim()}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: headers.Authorization,
      },
    };

    axios(config)
      .then(function (response) {
        toast.success(response.data.message + " Please Log in again");
        localStorage.clear();
        navigate("/");
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        toast.error(error.response.data.message);
      });
  };

  const newPassword = watch("newPassword");
  const confirmPassword = watch("confirmPassword");
  const passwordMatch = newPassword === confirmPassword;

  const validatePassword = (value) => {
    const trimmedValue = value.trim();
    if (trimmedValue.length === 0) {
      return "New Password is required";
    } else if (trimmedValue.length < 8) {
      return "New Password should be at least 8 characters long";
    }
    return true;
  };

  return (
    <>
      <div className="title d-flex justify-content-between align-items-center">
        <h2>Change Password</h2>
      </div>
      <div className="content">
        <form
          className="form"
          style={{ maxWidth: "350px" }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="form-group">
            <label> Old Password</label>
            <input
              type="password"
              placeholder="Enter old password"
              label="Old Password"
              {...register("oldPassword", {
                required: "Old Password is required",
                validate: (value) =>
                  value.trim().length > 0 || "Old Password is required",
              })}
            />
            {errors.oldPassword && (
              <span style={{ color: "red" }}>{errors.oldPassword.message}</span>
            )}
          </div>
          <div className="form-group">
            <label>New Password</label>
            <input
              type="password"
              placeholder="Enter new password"
              label="New Password"
              {...register("newPassword", {
                validate: validatePassword,
              })}
            />
            {errors.newPassword && (
              <span style={{ color: "red" }}>{errors.newPassword.message}</span>
            )}
          </div>
          <div className="form-group">
            <label>Confirm Password</label>
            <input
              type="password"
              placeholder="Enter confirm password"
              label="Confirm Password"
              {...register("confirmPassword", {
                required: "Confirm Password is required",
                validate: (value) =>
                  value.trim() === newPassword.trim() ||
                  "Passwords do not match",
              })}
            />
            {errors.confirmPassword && (
              <span style={{ color: "red" }}>
                {errors.confirmPassword.message}
              </span>
            )}
          </div>
          <div className="form-group mt-5">
            <button className="primery-btn mt-5">Submit</button>
          </div>
        </form>
      </div>
    </>
  );
}
