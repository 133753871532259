import { Navigate, Outlet } from "react-router-dom";
import Auth from "../../auth/Auth";

function OnboardLayout() {
  return (
    <>
      <div className="onboarding_root">
        <div className="login">
          <div className="container">
            <div className="logo">
              <img src="./assets/images/logo2.svg" alt=" " />
            </div>
            <div className="form-content">
              {Auth.isUserAuthenticated() ? (
                <Navigate to="/dashboard" />
              ) : (
                <Outlet />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OnboardLayout;
