import React from "react";
import { Line } from "react-chartjs-2";
import Chart from "chart.js/auto";

const DailyChart = ({ graphData }) => {
  const rawData = {
    silver: [
      {
        count: 1,
        hour: 3,
      },
      {
        count: 1,
        hour: 2,
      },
      {
        count: 2,
        hour: 2,
      },
      {
        count: 1,
        hour: 3,
      },
      {
        count: 1,
        hour: 7,
      },
    ],
    gold: [
      {
        count: 1,
        hour: 6,
      },
      {
        count: 1,
        hour: 7,
      },
      {
        count: 2,
        hour: 16,
      },
      {
        count: 1,
        hour: 19,
      },
      {
        count: 1,
        hour: 23,
      },
    ],
    platinum: [
      {
        count: 1,
        hour: 12,
      },
      {
        count: 1,
        hour: 16,
      },
      {
        count: 2,
        hour: 19,
      },
      {
        count: 1,
        hour: 10,
      },
      {
        count: 1,
        hour: 17,
      },
    ],
  };
  const colorLine = (item) => {
    if (item === "gold") {
      return "#E0BF65";
    } else if (item === "platinum") {
      return "#AEA18A";
    } else {
      return "#9C9C9C";
    }
  };
  // Extract data for each metal
  const metals = Object.keys(graphData);
  const datasets = metals.map((metal) => {
    // Sort the data by hour for each metal
    const sortedData = graphData[metal].sort((a, b) => a.hour - b.hour);

    // Map data to the hours of the day (12 AM to 11 PM)
    const countsHourly = new Array(24).fill(0); // Initialize array with 0 counts for each hour

    sortedData.forEach((item) => {
      const hour = item.hour;
      countsHourly[hour] += item.count;
    });

    return {
      label: metal,
      data: countsHourly,
      borderColor: colorLine(metal),
      fill: false,
    };
  });

  // Labels for the x-axis (12 AM to 11 PM)
  const labels = Array.from(
    { length: 24 },
    (_, index) =>
      `${index % 12 === 0 ? 12 : index % 12} ${index < 12 ? "AM" : "PM"}`
  );

  // Chart.js configuration object
  const chartData = {
    labels: labels,
    datasets: datasets.map((dataset) => ({
      ...dataset,
      fill: true, // Fill the area under the line
      pointRadius: 0, // Hide the data points
      tension: 0.4, // Set tension for cubic Bézier interpolation (0 = straight lines, 1 = full curves)
    })),
  };

  // Chart.js options with hover interactions
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: "index", // Enable index mode for hovering over data points
      intersect: false, // Disable intersection mode
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
      },
    },
    animation: {
      // Enable chart and data point animations
      duration: 1000, // Duration of animations in milliseconds
      easing: "linear", // Easing function to control the animation speed
      animateRotate: true, // Enable rotation animation for pie/doughnut charts
      animateScale: true, // Enable scale animation for scale-based (non-pie) charts
    },
  };

  return (
    <div style={{ maxWidth: "100%", margin: "0 auto" }} className="graph">
      <Line data={chartData} options={chartOptions} />
    </div>
  );
};

export default DailyChart;
