
import axios, { headers } from "../../../http/axios";
import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { GlobalContext } from "../../../App";
import Auth from "../../../auth/Auth";
const TopNav = () => {
  const navigate = useNavigate();
  const { setSpinner } = useContext(GlobalContext);
  const { handleSubmit, reset } = useForm();
  const location = useLocation();

  // const handleLogout = async () => {
  //   setSpinner(true);
  //   localStorage.clear()
  //   // window.location.reload()
  //   navigate("/")
  //   setSpinner(false);
  // }

  // -------------------------------------------------logout api ----
  const handleLogout = (e) => {
    e.preventDefault()
    setSpinner(true);
    try {

      axios.post(`${process.env.REACT_APP_BASE_URL}/api/admin/logout`, {}, { headers: { ...headers, "Content-Length": 0 } })?.then((res) => {
        if (res?.status >= 200) {
          localStorage.clear()
          // window.location.reload()
          setSpinner(false);
          navigate("/")
          toast.success(res?.data?.message);
        }
      }).catch((error) => {
        toast.error(error?.response?.data?.message);
        setSpinner(false);
      })

    } catch (error) {
    }
  };

  return (
    <div className="dropdown">
      <div
        className="dropdown-toggle"
        type="button"
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <img src="./assets/images/down-arrow.svg" alt=" " />
      </div>
      <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        <li>
          <button className="dropdown-item" onClick={handleLogout}>
            Log Out
          </button>
        </li>
      </ul>
    </div>
  );
};

export default TopNav;
