import { Navigate, Outlet } from "react-router-dom";
import Auth from "../../auth/Auth";
import SideBar from "../sidebar/SideBar";
import TopNav from "../common-Component/navbar/TopNav";

function DashboardLayout() {
  return (
    <div className="dashboard_root">
      <SideBar />

      <div className="topbar">
        <img src="./assets/images/user.svg" alt=" " />
        <TopNav />
      </div>

      <div className="dashboard">
        {!Auth.isUserAuthenticated() ? <Navigate to="/login" /> : <Outlet />}

      </div>
    </div>
  );
}

export default DashboardLayout;
