import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import BackNavigate from "../common-Component/BackNavigate";
import { GlobalContext } from "../../App";
import axios, { headers } from "../../http/axios";
import { toast } from "react-toastify";
import CommonInput from "../common-Component/form/CommonInput";
import { Controller, useForm } from "react-hook-form";
import { Rating } from "react-simple-star-rating";

export function AddTestimonials() {
  const [testDetailListing, setTestDetailListing] = useState(false);
  const { setSpinner } = useContext(GlobalContext);
  const { id } = useParams();
  const [roles, setRoles] = useState();
  const [rating, setRating] = useState(0);
  const navigate = useNavigate();

  // -----use form login validation ----
  const {
    register,
    watch,
    setValue,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      client_name: "",
      company_name: "",
      rating: "",
      role: "",
    },
  });

  // ---- -------*tetestimonials detail  api *------------
  const testimonialsDetailApi = (async) => {
    setSpinner(true);
    try {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/api/admin/testimonials/${id}`, {
          headers: headers,
        })
        ?.then((res) => {
          if (res?.status >= 200) {
            setTestDetailListing(res?.data?.data);
            console.log("Sdssddds", res?.data?.data.role);

            setValue("role", res?.data?.data.role); // ------- * get key value pair from a object & object into object
            // @Object.entries(res?.data?.data?.business_info)
            for (const [key, value] of Object.entries(res?.data?.data)) {
              setValue(key, value);
            }
            setSpinner(false);
          }
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message);
          setSpinner(false);
        });
    } catch (error) {}
  };

  useEffect(() => {
    getTestRoles();
  }, []);
  useEffect(() => {
    getTestRoles();
    if (id) {
      testimonialsDetailApi();
      setValue("role", testDetailListing?.role);
    }
  }, [id]);
  // --------------- * add testimonials
  const addTestimonialsApi = async (data) => {
    setSpinner(true);
    // ------ * for *add(post)* & *editData(put)*
    let method = id ? axios.put : axios.post;
    try {
      method(
        `${process.env.REACT_APP_BASE_URL}/api/admin/testimonials${
          id ? "/" + id : ""
        }`,
        data,
        { headers: headers }
      )
        // axios.post(`${process.env.REACT_APP_BASE_URL}/api/admin/testimonials`,data, { headers: headers })
        ?.then((res) => {
          if (res?.status >= 200) {
            // postListingApi();
            toast.success(res?.data?.message);
            // testimonialsDetailApi();
            setSpinner(false);
            navigate("/testimonials");
          }
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message);
          setSpinner(false);
        });
    } catch (error) {}
  };
  // -------* get roles
  const getTestRoles = (async) => {
    setSpinner(true);
    try {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/api/testimonial-roles          `,
          { headers: headers }
        )
        ?.then((res) => {
          if (res?.status >= 200) {
            toast.success(res?.data?.message);
            setRoles(res?.data?.data);
            setSpinner(false);
          }
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message);
          setSpinner(false);
        });
    } catch (error) {}
  };

  // Optinal callback functions
  const onPointerEnter = () => {};
  const onPointerLeave = () => {};
  const onPointerMove = (event) => {};

  return (
    <>
      <div className="title d-flex justify-content-between align-items-center">
        <h2>
          <BackNavigate />
          {id ? "Edit " : "Add "}
          Testimonials
        </h2>
      </div>
      <div className="content">
        <form
          className="testimonials-form form"
          onSubmit={handleSubmit(addTestimonialsApi)}
        >
          <div className="d-flex">
            <div className="form-group">
              <CommonInput
                type="text"
                label="Client Name"
                className="me-2 "
                id="clientName"
                required
                placeholder="Enter name"
                inputClass={errors.client_name ? " border-danger" : ""}
                register={{
                  ...register("client_name", {
                    required: true,
                  }),
                }}
              />
              {errors.client_name?.type === "required" && (
                <p role="alert" className="alert-msg text-danger">
                  Client name is required.
                </p>
              )}
            </div>
            <div className="form-group">
              <CommonInput
                type="text"
                required
                label="Company Name"
                className="me-2 "
                id="companyName"
                placeholder="Company name"
                inputClass={errors.company_name ? " border-danger" : ""}
                register={{
                  ...register("company_name", {
                    required: true,
                  }),
                }}
              />
              {errors.company_name?.type === "required" && (
                <p role="alert" className="alert-msg text-danger">
                  Company name is required.
                </p>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="ClientRole">
                Role <span className="text-danger"> *</span>
              </label>
              <select
                defaultValue={testDetailListing?.role}
                id="ClientRole"
                className={`select_control select_icon-arrow ${
                  errors.role?.type ? "border-danger" : ""
                }`}
                {...register("role", {
                  required: true,
                })}
              >
                <option value="" className="option">
                  Select Role
                </option>
                {roles?.map((item, index) => {
                  return (
                    <option value={item} key={index + 1}>
                      {item}
                    </option>
                  );
                })}
              </select>
              {errors.role?.type === "required" && (
                <p role="alert" className="alert-msg text-danger">
                  Client role is required.
                </p>
              )}
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="testContent">
              Content
              <span className="subLabel text-muted h4 ms-2">
                (
                {watch()?.content === ""
                  ? "Max limit is 500 characters"
                  : 500 - watch()?.content?.length + " characters are left"}
                )
              </span>
              <span className="text-danger"> *</span>
            </label>
            <textarea
              maxlength="500"
              className={` ${errors.content?.type ? "border-danger" : ""}`}
              id="testContent"
              {...register("content", {
                required: true,
              })}
            ></textarea>
            {errors.content?.type === "required" && (
              <p role="alert" className="alert-msg text-danger">
                Content is required.
              </p>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="">Rating</label>
            {/* <img src="./assets/images/rating.svg" alt=" " /> */}
            <Controller
              name="rating"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Rating
                  initialValue={testDetailListing?.rating}
                  onClick={(value) => field.onChange(value)}
                  value={field.value}
                  allowHover={false}
                  onPointerEnter={onPointerEnter}
                  onPointerLeave={onPointerLeave}
                  transition
                  onPointerMove={onPointerMove}
                  fillColor={"#1065d1"}
                />
              )}
            />
          </div>
          <div className="form-group"></div>
          <button type="submit" className="primery-btn mt-4">
            Save
          </button>
        </form>
      </div>
    </>
  );
}
