import React, { useContext, useState } from "react";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios, { headers } from "../../http/axios";
import { GlobalContext } from "../../App";
import { toast } from "react-toastify";
import CommonInput from "../common-Component/form/CommonInput";

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setSpinner } = useContext(GlobalContext);
  // --hide show pass -----
  //  ----- NEW PASS
  const [password, setPassword] = useState(false);
  const showpassword = () => [setPassword(!password)];
  //  ----- Confirm PASS
  const [confirmPassword, setConfirmPassword] = useState(false);
  const showConfirmpassword = () => [setConfirmPassword(!confirmPassword)];
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  // ----------------------- reset password api ------------------------
  const resetPassword = (data, e) => {
    e.preventDefault()
    setSpinner(true);
    const reqBody = {
      ...data,
      email: location.state.email,
      token: location.state.token,
    };
    axios.post(`${process.env.REACT_APP_BASE_URL}/api/admin/password/reset`, reqBody, { headers: headers })?.then((res) => {
      if (res?.status === 200) {
        setSpinner(false);
        navigate("/")
        toast.success(res?.data?.message);
      }
    }).catch((error) => {
      toast.error(error?.response?.data?.message);
      setSpinner(false);
    })
  }
  return (
    <>
      <div className="sign-info">
        <h2>Reset Password</h2>
        <p>Please enter your new password</p>
      </div>
      <form className="form" onSubmit={handleSubmit(resetPassword)}>
        {/* ---New password password input --- */}
        <div className="form-group">
          <div className="paddword-input">
            <CommonInput
              inputclassName={`${errors.password || (watch("password") !== watch("password_confirmation") && watch("password_confirmation")) ? " border-danger" : ""}`}
              type={password ? "text" : "password"}
              label="New Password"
              placeholder="Enter password"
              register={{ ...register("password", { required: true }) }}
            />
            <div className="form-group-append " onClick={showpassword}>
              {password ? (
                <img src="./assets/images/eye-open.svg" alt="" className="eye-open" />
              ) : (
                <img src="./assets/images/eye-closed.svg" alt=" " className="eye-open" />
              )}
              {/* --------------------- error ---------------------------------- */}
              {errors.password && (
                <p className="alert-msg text-danger">
                  New password is required
                </p>
              )}
            </div>
          </div>
        </div>
        {/* ---New confirm password input --- */}
        <div className="form-group">
          <div className="paddword-input">
            <CommonInput
              inputclassName={`${errors.password_confirmation || (watch("password") !== watch("password_confirmation") && watch("password_confirmation")) ? " border-danger" : ""}`}
              type={confirmPassword ? "text" : "password"}
              label="Confirm Password"
              placeholder="Enter password"
              register={{ ...register("password_confirmation", { required: true }) }}
            />
            <div className="form-group-append " onClick={showConfirmpassword}>
              {confirmPassword ? (
                <img src="./assets/images/eye-open.svg" alt="" className="eye-open" />
              ) : (
                <img src="./assets/images/eye-closed.svg" alt=" " className="eye-open" />
              )}
            </div>
            {/* --------------------- error ---------------------------------- */}
            {errors.password_confirmation && (
              <p className="alert-msg text-danger">
                Confirm password is required
              </p>
            )}
          </div>
        </div>
        {/* --------------------- errors ---------------------------------- */}
        {/* {(watch("password") !== watch("password_confirmation") && watch("password_confirmation")) ?
          <p className="alert-msg text-danger">Password and confirm password doesn't match. </p> : ""}

        {(watch("password")?.trim()?.length !== 6 && watch("password_confirmation")) ?
          <p className="alert-msg text-danger">Password length should be at least 6 characters. </p> : ""} */}

        <div className="form-group">
          <button className="primery-btn w-100">Submit</button>
        </div>
      </form>
    </>
  );
};
export default ResetPassword;
