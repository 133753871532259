import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import CommonModal from "../common-Component/modal/CommonModal";
import axios, { headers } from "../../http/axios";
import { GlobalContext } from "../../App";
import { toast } from "react-toastify";
import Pagination from "../common-Component/Pagination";
import { useForm } from "react-hook-form";

export function Projects() {
  const [projectData, setProjectData] = useState();
  const [projectDetail, setProjectDetail] = useState();
  const [addProject, setAddProject] = useState(false);
  const [editProject, setEditProject] = useState(false);
  const { setSpinner } = useContext(GlobalContext);
  const [paginationData, setPaginationData] = useState();
  const [pages, setPages] = useState(1);
  const [pageRefresh, setpageRefresh] = useState(false);
  const [search, setSearch] = useState("");
  const [testDelete, setTestDelete] = useState({
    state: false,
    id: "",
  });

  const [previewImage, setPreviewImage] = useState(null);
  const [pid, setpid] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    clearErrors,
  } = useForm({ mode: "onChange" });

  const onSubmitProject = async (data) => {
    setSpinner(true);
    const key = { imageKey: "" };
    const formData = new FormData();
    formData.append("image", data.image[0]);
    try {
      const bannerResponse = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/upload-image`,
        formData
      );
      console.log("response", bannerResponse.data.image);
      key.imageKey = bannerResponse.data.image;

      const finalResponse = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/admin/projects`,
        {
          title: data?.title,
          description: data?.description,
          image: key.imageKey,
        },
        {
          headers: headers,
        }
      );
      toast.success(finalResponse.data.message);
      setpageRefresh(!pageRefresh);
      setAddProject(false);
    } catch (e) {
    } finally {
      setSpinner(false);
    }
  };

  const onSubmitEdittedProject = async (data) => {
    setSpinner(true);
    const key = { imageKey: "" };
    if (previewImage) {
      const formData = new FormData();
      formData.append("image", data.image[0]);
      try {
        const bannerResponse = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/upload-image`,
          formData
        );
        console.log("response", bannerResponse.data.image);
        key.imageKey = bannerResponse.data.image;
      } catch (e) {
        setSpinner(false);
      }
    }
    try {
      const finalResponse = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/admin/projects/${pid}`,
        {
          title: data?.title,
          description: data?.description,
          image: key.imageKey ? key.imageKey : projectDetail.image,
        },
        {
          headers: headers,
        }
      );
      setpageRefresh(!pageRefresh);
      toast.success(finalResponse.data.message);
      setEditProject(false);
    } catch (e) {
    } finally {
      setSpinner(false);
    }
  };

  const getImage = watch("image")?.[0];

  const handleFileUpload = (event, setImage) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setImage(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (getImage) {
      handleFileUpload({ target: { files: [getImage] } }, setPreviewImage);
    }
  }, [getImage]);
  const MAX_IMAGE_SIZE = 1024 * 1024; // 1MB in bytes
  useEffect(() => {
    getProjectData();
  }, [pages, pageRefresh]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      // Call your search function here
      getProjectData();
    }, 500); // Delay time in milliseconds

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [search]);

  const getProjectData = () => {
    setSpinner(true);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/admin/projects?page=${pages}&size=10&search=${search}`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        setPaginationData(response.data.data);
        setProjectData(response.data.data.data);
      })
      .finally(() => {
        setSpinner(false);
      });
  };

  const onClickEditProjectButton = (id) => {
    clearErrors();
    setpid(id);
    setSpinner(true);
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/admin/projects/${id}`, {
        headers: headers,
      })
      .then((response) => {
        setValue("title", response?.data?.data?.title);
        setValue("description", response?.data?.data?.description);
        setProjectDetail(response?.data?.data);
        setEditProject(true);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      })
      .finally(() => {
        setSpinner(false);
      });
  };

  const onClickAddProjectButton = () => {
    clearErrors();
    setValue("title", "");
    setValue("description", "");
    setProjectDetail("");
    setPreviewImage("");
    setAddProject(true);
  };

  const handleDeleteProject = (projectId) => {
    axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}/api/admin/projects/${projectId}`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        toast.success("Project deleted ,Successfully!");
      })
      .finally(() => {
        setSpinner(false);
        setpageRefresh(!pageRefresh);
        setTestDelete({ state: false });
      });
  };

  const handleFileReset = (file) => {
    if (file === "image") {
      setPreviewImage("");
      setValue("image", "");
      console.log("project dta", projectDetail);
      setProjectDetail({ ...projectData, image: "" });
    }
  };
  return (
    <>
      <div className="title d-flex justify-content-between align-items-center">
        <h2>Projects</h2>
        <div className="d-flex search-dropdown">
          <div className="search-box">
            <img src="./assets/images/search.svg" alt="" />
            <input
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              type="search"
              placeholder="Search Projects"
            />
          </div>
          <button
            className="primery-btn"
            onClick={() => onClickAddProjectButton()}
          >
            <img src="./assets/images/plus.svg" alt="" /> Add
          </button>
          {/* ---project modal ---  */}
          <CommonModal
            show={addProject}
            onHide={() => setAddProject(false)}
            className="add-post-modal"
            header={
              <>
                <h3 className="add-project">Add Project</h3>
                <p>Please enter project details</p>
              </>
            }
            body={
              <>
                {/* --- link inputs --- */}
                <form onSubmit={handleSubmit(onSubmitProject)}>
                  <div className="form-group">
                    <label>
                      Title <span className="text-danger"> *</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter title"
                      {...register("title", {
                        required: "Title is required",
                        validate: (value) =>
                          value.trim().length > 0 || "Title is required",
                      })}
                    />
                    {errors.title && (
                      <span style={{ color: "red" }}>
                        {errors.title.message}
                      </span>
                    )}
                  </div>
                  <div className="form-group">
                    <label>
                      Description <span className="text-danger"> *</span>
                    </label>
                    <textarea
                      placeholder="Enter description"
                      {...register("description", {
                        required: "Description is required",
                        validate: (value) =>
                          value.trim().length > 0 || "Description is required",
                      })}
                    />
                    {errors.description && (
                      <span style={{ color: "red" }}>
                        {errors.description.message}
                      </span>
                    )}
                  </div>
                  <div className="form-group">
                    <label>
                      Upload Image <span className="text-danger"> *</span>
                    </label>
                    <div className="set-cross-icon">
                      <label htmlFor="upload" className="upload_file">
                        <input
                          accept="image/*"
                          id="upload"
                          type="file"
                          className="d-none"
                          {...register("image", {
                            required: "Image is required",
                            validate: (value) => {
                              if (!value[0]) return " Image is required";
                              const allowedTypes = [
                                "image/jpeg",
                                "image/png",
                                "image/jpg",
                              ];
                              if (!allowedTypes.includes(value[0].type)) {
                                return "Only images (JPEG, PNG) are allowed";
                              }
                              if (value[0].size > MAX_IMAGE_SIZE) {
                                return "Image size should not exceed 1MB";
                              }
                              return true;
                            },
                          })}
                        />
                        <img
                          src={
                            previewImage
                              ? previewImage
                              : "./assets/images/upload-img.svg"
                          }
                          alt=" "
                        />
                      </label>
                      {previewImage && (
                        <span
                          className="cross"
                          onClick={() => {
                            handleFileReset("image");
                          }}
                        >
                          <img src="./assets/images/cross.svg" alt="" />
                        </span>
                      )}
                      {errors.image && (
                        <span style={{ color: "red" }}>
                          {errors?.image?.message}
                        </span>
                      )}
                    </div>
                  </div>
                  {/* ---submit btn -- */}
                  <div className="modal-footer">
                    <button
                      type="submit"
                      className="primery-btn"
                      data-bs-dismiss="modal"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </>
            }
          />
          <CommonModal
            show={editProject}
            onHide={() => setEditProject(false)}
            className="add-post-modal"
            header={
              <>
                <h3>Edit Project</h3>
              </>
            }
            body={
              <>
                {/* --- link inputs --- */}
                <form onSubmit={handleSubmit(onSubmitEdittedProject)}>
                  <div className="form-group">
                    <label>
                      Title <span className="text-danger"> *</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter title"
                      {...register("title", {
                        required: "Title is required",
                        validate: (value) =>
                          value.trim().length > 0 || "Title is required",
                      })}
                    />
                    {errors.title && (
                      <span style={{ color: "red" }}>
                        {errors.title.message}
                      </span>
                    )}
                  </div>
                  <div className="form-group">
                    <label>
                      Description <span className="text-danger"> *</span>
                    </label>
                    <textarea
                      placeholder="Enter description"
                      {...register("description", {
                        required: "Description is required",
                        validate: (value) =>
                          value.trim().length > 0 || "Description is required",
                      })}
                    />
                    {errors.description && (
                      <span style={{ color: "red" }}>
                        {errors.description.message}
                      </span>
                    )}
                  </div>
                  <div className="form-group">
                    <label>
                      Upload Image <span className="text-danger"> *</span>
                    </label>
                    <div className="set-cross-icon">
                      <label htmlFor="upload1" className="upload_file">
                        <input
                          accept=".jpg,.jpeg,.png"
                          id="upload1"
                          type="file"
                          className="d-none"
                          {...register("image", {
                            required:
                              previewImage || projectDetail?.image
                                ? false
                                : "Image is required",
                            validate: (value) => {
                              if (value[0]) {
                                const allowedTypes = [
                                  "image/jpeg",
                                  "image/png",
                                  "image/jpg",
                                ];
                                if (!allowedTypes.includes(value[0].type)) {
                                  return "Only images (JPEG, PNG) are allowed";
                                }
                                if (value[0].size > MAX_IMAGE_SIZE) {
                                  return "Image size should not exceed 1MB";
                                }
                                return true;
                              }
                            },
                          })}
                        />
                        {previewImage || projectDetail?.image ? (
                          <>
                            <img
                              src={
                                previewImage
                                  ? previewImage
                                  : `${process.env.REACT_APP_MEDIA_URL}/${projectDetail?.image}`
                              }
                              alt=" "
                            />
                          </>
                        ) : (
                          <img src="./assets/images/upload-img.svg" alt=" " />
                        )}
                      </label>
                      {(previewImage || projectDetail?.image) && (
                        <span
                          className="cross"
                          onClick={() => {
                            handleFileReset("image");
                          }}
                        >
                          <img src="./assets/images/cross.svg" alt="x" />
                        </span>
                      )}
                    </div>
                    {errors.image && (
                      <span style={{ color: "red" }}>
                        {errors.image.message}
                      </span>
                    )}
                  </div>
                  {/* ---submit btn -- */}
                  <div className="modal-footer">
                    <button
                      type="submit"
                      className="primery-btn"
                      data-bs-dismiss="modal"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </>
            }
          />
        </div>
      </div>
      <div className="content">
        <div className="responsive-table">
          <table>
            <thead>
              <tr>
                <th width="10%">S.No.</th>
                <th width="30%">Title</th>
                <th width="30%">Description</th>
                <th width="20%">Image</th>
                <th width="10%">Action</th>
              </tr>
            </thead>
            <tbody>
              {projectData?.length > 0 &&
                projectData?.map((ele, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{ele?.title}</td>
                      <td>
                        {ele?.description ? (
                          ele?.description.length > 100 ? (
                            `${ele?.description?.substring(0, 100)} ...`
                          ) : (
                            ele?.description
                          )
                        ) : (
                          <span className="empty-data">-</span>
                        )}
                      </td>
                      <td>
                        <img
                          src={`${process.env.REACT_APP_MEDIA_URL}/${ele?.image}`}
                          alt=""
                          className="banner-img"
                        />
                      </td>
                      <td>
                        <Link to={"/projects/project-details/" + ele?.id}>
                          <img
                            src="./assets/images/eye.svg"
                            alt=""
                            className="edit"
                          />
                        </Link>

                        <img
                          onClick={() => onClickEditProjectButton(ele.id)}
                          src="./assets/images/edit.svg"
                          alt=""
                          className="edit"
                        />

                        <img
                          src="./assets/images/trash.svg"
                          alt=" "
                          className="edit"
                          //  onClick={() => handleDeleteProject(ele?.id)}
                          onClick={() =>
                            setTestDelete({ state: true, id: ele?.id })
                          }
                        />
                      </td>
                    </tr>
                  );
                })}
              {projectData?.length === 0 && (
                <div className="alert-text no-data">
                  <div className="error-data">
                    <p className="font-size-1.5"> No data found</p>
                  </div>
                </div>
              )}
            </tbody>
          </table>
          {projectData?.length > 0 && (
            <div className="pagination">
              <Pagination
                totalPages={paginationData?.last_page}
                currentPage={paginationData?.current_page}
                page={pages}
                setPage={(page) => setPages(page)}
              />
            </div>  
          )}
        </div>
      </div>
      <CommonModal
        show={testDelete.state}
        onHide={() =>
          setTestDelete({
            state: false,
          })
        }
        className="add-post-modal"
        header={<></>}
        body={
          <>
            <h3>Delete Project</h3>
            <p>Are you sure you want to delete this Project?</p>
            <div className="btnContainer-modal d-flex">
              <button
                className="primery-btn me-3"
                onClick={() => handleDeleteProject(testDelete?.id)}
              >
                Yes
              </button>
              <button
                className="secondry-btn"
                onClick={() =>
                  setTestDelete({
                    state: false,
                  })
                }
              >
                No
              </button>
            </div>
          </>
        }
      />
    </>
  );
}
