import React from "react";
import { Modal } from "react-bootstrap";

export default function CommonModal(props) {
    return (
        <Modal show={props?.show} onHide={props?.onHide} centered className={`${props?.className ? props?.className : ""} `}>
            <Modal.Header>
                {/* ----- header clse button --- */}
                <div className="custom-modal-header">
                    {props?.header}
                </div>

            </Modal.Header>
            {/* ------- body ------ */}
            <Modal.Body>
                <div className="custom-modal-body">
                    {props?.body}
                </div>
            </Modal.Body>

        </Modal >
    );
}
