import React from 'react';

function CommonInput({ register,
    id,
    type,
    label,
    placeholder,
    className,
    labelClass,
    icon,
    checkBox,
    inputClass,
    disable,
    checked,
    minlength,
    maxLength,
    onChange,
    name,
    required,
    onKeyPress,
    value,
    defaultChecked,
    errors }) {
    return (
        <>
            <div className={`input-box ${className ? className : ""}`}>
                {label &&
                     <label htmlFor={id} className='pointer'>
                     {label}
                     {required ? <span className="text-danger"> *</span> : ""}
                    </label>
                }
                {/* ======input icon  */}
                {icon && <span className="input-box-img">
                    <img src={icon} alt=" " />
                </span>}
                {/* input box  */}
                <div className={`${checkBox ? "check_boxx custom-checkbox" : ""}`}>
                    <input
                    name={name}
                        type={type}
                        // name={name}
                        id={id}
                        placeholder={placeholder}
                        className={`text-input ${inputClass ? inputClass : ""} `}
                        // value={value}
                        maxLength={maxLength}
                        minLength={minlength}
                        disabled={disable}
                        // accept={accept}
                        checked={checked}
                        onKeyPress={onKeyPress}
                        autoComplete="off"
                        {...(value?{value:value}:"")}
                        // value={"value"}
                        autoCapitalize="on"
                        {...(onChange?{onChange:onChange}:"")}
                        {...register}
                        defaultChecked={defaultChecked}
                    />
                    {checkBox &&
                        <span className="checkbox_check">
                        </span>}
                </div>
            </div >
        </>
    );
}

export default CommonInput;