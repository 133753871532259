import React, { useContext, useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios, { headers } from "../../http/axios";
import { GlobalContext } from "../../App";
import { toast } from "react-toastify";
import BackNavigate from "../common-Component/BackNavigate";
export function UserDetails() {
  const [userDetails, setUserDetails] = useState();
  const { setSpinner } = useContext(GlobalContext);
  const { id } = useParams();
  const navigate = useNavigate();
  // ---- -------* listing api *------------
  const userListingApi = (async) => {
    setSpinner(true);
    try {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/api/admin/users/${id}`, {
          headers: headers,
        })
        ?.then((res) => {
          if (res?.status >= 200) {
            setUserDetails(res?.data?.data);
            toast.success(res?.data?.message);
            setSpinner(false);
          }
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message);
          if (error?.response?.data?.message === "User not found") {
            navigate("/users");
          }
          setSpinner(false);
        });
    } catch (error) {}
  };
  useEffect(() => {
    userListingApi();
  }, []);

  console.log("user de", userDetails);
  return (
    <>
      <div className="title d-flex justify-content-between align-items-center">
        <h2>
          <BackNavigate />
          User Details
        </h2>
        <div className="d-flex search-dropdown">
          <Link
            to={`/users/user-post/${id}`}
            state={{ from: id }}
            className="primery-btn"
          >
            {" "}
            Posts
          </Link>
        </div>
      </div>
      <div className="content">
        {/* -------- * package details  */}
        {userDetails?.subscription?.package === "gold" && (
          <div className="package-detail">
            <div className="inner-title">
              <h3>
                Package Details
                {/* <img src="./assets/images/edit2.svg" alt="" /> */}
              </h3>
            </div>

            <div className="gold-plan d-flex">
              <p>Gold Plan</p>
              <span>
                <sup>$</sup> <b>{userDetails?.subscription?.amount}</b>
              </span>
            </div>
          </div>
        )}
        {userDetails?.subscription?.package === "platinum" && (
          <div className="package-detail">
            <div className="inner-title">
              <h3>
                Package Details
                {/* <img src="./assets/images/edit2.svg" alt="" /> */}
              </h3>
            </div>

            <div className="platinum-plan d-flex">
              <p>Platinum Plan</p>
              <span>
                <sup>$</sup> <b>{userDetails?.subscription?.amount}</b>
              </span>
            </div>
          </div>
        )}
        {userDetails?.subscription?.package === "silver" && (
          <div className="package-detail">
            <div className="inner-title">
              <h3>
                Package Details
                {/* <img src="./assets/images/edit2.svg" alt="" /> */}
              </h3>
            </div>

            <div className="silver-plan d-flex">
              <p>Silver Plan</p>
              <span>
                <sup>$</sup> <b>{userDetails?.subscription?.amount}</b>
              </span>
            </div>
          </div>
        )}
        {/* ------------- Profile Info  */}
        <div className="user-profile-info">
          <div className="inner-title">
            <h3>Profile Info</h3>
          </div>
          <div className="user-social-post">
            <div className="social-box">
              <b>Full Name</b>
              <p>
                {userDetails?.name ? (
                  userDetails?.name
                ) : (
                  <span className="empty-data gray-text">---- </span>
                )}
              </p>
            </div>
            <div className="social-box">
              <b>Email</b>
              <p>
                {userDetails?.email ? (
                  userDetails?.email
                ) : (
                  <span className="empty-data gray-text">---- </span>
                )}
              </p>
            </div>
            <div className="social-box">
              <b>Phone</b>
              <p>
                {userDetails?.phone_number ? (
                  "+" + userDetails?.phone_number
                ) : (
                  <span className="empty-data gray-text">---- </span>
                )}
              </p>
            </div>
          </div>
        </div>
        {/* ------- Business Info  */}
        <div className="user-business-info">
          <div className="inner-title">
            <h3>
              Business Info
              {/* <img src="./assets/images/edit2.svg" alt="" /> */}
            </h3>
          </div>
          <div className="user-social-post">
            <div className="social-box">
              <b>Business Name</b>
              <p>
                {userDetails?.business_info?.business_name ? (
                  userDetails?.business_info?.business_name
                ) : (
                  <span className="empty-data gray-text">---- </span>
                )}
              </p>
            </div>
            <div className="social-box">
              <b>Business Phone</b>
              <p>
                {userDetails?.business_info?.business_phone ? (
                  "+" + userDetails?.business_info?.business_phone
                ) : (
                  <span className="empty-data gray-text">---- </span>
                )}
              </p>
            </div>
            <div className="social-box">
              <b>Business Address</b>
              <p>
                {userDetails?.business_info?.business_address ? (
                  userDetails?.business_info?.business_address
                ) : (
                  <span className="empty-data gray-text">---- </span>
                )}
              </p>
            </div>
            <div className="social-box">
              <b>Do you have a website?</b>
              <p>
                {userDetails?.business_info?.website !== "" ? (
                  userDetails?.business_info?.website === 1 ? (
                    "Yes"
                  ) : (
                    "No"
                  )
                ) : (
                  <span className="empty-data gray-text">---- </span>
                )}
              </p>
            </div>
            <div className="social-box">
              <b>Business Website</b>
              <p>
                {userDetails?.business_info?.website_url ? (
                  userDetails?.business_info?.website_url
                ) : (
                  <span className="empty-data gray-text">---- </span>
                )}
              </p>
            </div>
            {/* <div className="social-box">
                            <b>Business Social Media</b>
                            <p><span className="empty-data gray-text">---- </span>
                                http//facbook.galaxyworld.com
                            </p>
                            <div className="d-flex">
                                <Link href="#" className="disabled">
                                    <img
                                        src="./assets/images/facebook.svg" alt="" />
                                </Link>
                                <Link href="#" className="disabled"><img
                                    src="./assets/images/instagram.svg" alt="" />
                                </Link>
                            </div>
                        </div> */}
          </div>
        </div>
        {/* ---- * Business Details */}
        <div className="user-business-details">
          <div className="inner-title">
            <h3>
              Business Details
              {/* <img src="./assets/images/edit2.svg" alt="" /> */}
            </h3>
          </div>
          <div className="user-social-post">
            <div className="bus-con">
              <b>
                Every business is special in its own way. Please tell us yours
                speciality.
              </b>
              <p>
                {userDetails?.business_info?.speciality ? (
                  userDetails?.business_info?.speciality
                ) : (
                  <span className="empty-data gray-text">---- </span>
                )}
              </p>
            </div>
            <div className="bus-con">
              <b>
                What is unique about your business that will make your business
                stand up in crowd.
              </b>
              <p>
                {userDetails?.business_info?.uniqueness ? (
                  userDetails?.business_info?.uniqueness
                ) : (
                  <span className="empty-data gray-text">---- </span>
                )}
              </p>
            </div>
            <div className="bus-con">
              <b>Please tell us about your products and services.</b>
              <p>
                {userDetails?.business_info?.product_services ? (
                  userDetails?.business_info?.product_services
                ) : (
                  <span className="empty-data gray-text">---- </span>
                )}
              </p>
            </div>
          </div>
        </div>
        {/* -------- Business Other Details  */}
        <div className="other-user-business-details">
          <div className="inner-title">
            <h3>
              Business Other Details
              {/* <img src="./assets/images/edit2.svg" alt="" /> */}
            </h3>
          </div>
          <div className="user-social-post border-0 mb-0">
            <div className="bus-con">
              <b>What is your main goal?</b>
              {userDetails?.business_info?.goals?.length > 0 ? (
                <ul>
                  {userDetails?.business_info?.goals
                    ?.filter((ele) => {
                      return ele !== "All of the above";
                    })
                    .map((item, index) => {
                      return <li>{item}</li>;
                    })}
                </ul>
              ) : (
                <span className="empty-data gray-text">N/A </span>
              )}
            </div>
            <div className="bus-con">
              <b>Upload files</b>

              <div className="attachment-box">
                {userDetails?.business_info?.attachments?.length > 0 ? (
                  userDetails?.business_info?.attachments?.map(
                    (item, index) => {
                      return (
                        <>
                          <img
                            src={`${process.env.REACT_APP_MEDIA_URL}${item?.file}`}
                            alt=""
                          />
                        </>
                      );
                    }
                  )
                ) : (
                  <div className="placeholder-img">
                    <img src={"./assets/images/placeholder-img.png"} alt="" />
                  </div>
                )}
              </div>
            </div>
            {/* <div className="bus-con">
                            <b>Products descriptions</b>
                            <p>{userDetails?.business_info?.product_descriptions ? userDetails?.business_info?.product_descriptions : <span className="empty-data gray-text">---- </span>}</p>

                        </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
