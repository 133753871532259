import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { GlobalContext } from "../../App";
import axios, { headers } from "../../http/axios";
import { toast } from "react-toastify";
import BackNavigate from "../common-Component/BackNavigate";
import moment from "moment";

export function UserPostDetails() {
  const location = useLocation();
  const { id } = useParams();
  const { setSpinner } = useContext(GlobalContext);
  const [postList, setPostList] = useState();
   // ---- -------* listing api *------------
 const postListingApi = async () => {
  setSpinner(true);
  try {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/admin/posts/${id}`,{ headers: headers }
      )
      ?.then((res) => {
        if (res?.status >= 200) {
          
          setPostList(res?.data?.data);
          toast.success(res?.data?.message);
          setSpinner(false);
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        setSpinner(false);
      });
  } catch (error) { }

  };
 useEffect(() => {
  postListingApi();
}, []);
  return (
    <>
      <div className="title d-flex justify-content-between align-items-center">
        <h2>
          <BackNavigate/>
          Post Details
        </h2>
      </div>
      <div className="content">
        <div className="bus-con">
          <b>Date Created</b>
          <p>{moment(postList?.date).format("DD/MM/YYYY")}</p>
        </div>
        <div className="bus-con">
          <b>Social Platform Links</b>
          {postList?.social_links?.map((item, index) => {
            return (
              
            <>
                <a href={item?.link.includes("https://")?item?.link:"https://"+item?.link} target="_blank" rel="noreferrer">
            {item?.platform==="facebook"&&<img src="./assets/images/facebook2.svg" alt=" " />}
            {item?.platform==="instagram"&&<img src="./assets/images/instagram2.svg" alt=" " />}
            {item?.platform==="twitter"&&<img src="./assets/images/twitter.svg" alt=" " />}
            {item?.platform==="linkedin"&&<img src="./assets/images/linkdin2.svg" alt=" " />}
           {item?.link}
                </a>
              </>
          )})}
        </div>
      </div>
    </>
  );
}
