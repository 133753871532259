import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { GlobalContext } from "../../App";
import { toast } from "react-toastify";
import axios, { headers } from "../../http/axios";
import Table from "../common-Component/table/Table";
import Pagination from "../common-Component/Pagination";
import CommonModal from "../common-Component/modal/CommonModal";

export function Testimonial() {
  const { id } = useParams();
  const { setSpinner } = useContext(GlobalContext);
  const [testimonialData, setTestimonialData] = useState();
  const navigate = useNavigate(); // ---- * pagination
  const [paginationCount, setPaginationCount] = useState({});
  const [pages, setPage] = useState(1);
  // --* searching
  const [searchEvent, setSearchEvent] = useState("");
  //  delete state
  const [testDelete, setTestDelete] = useState({
    state: false,
    id: "",
  });
  // ---- -------* listing api *------------
  const testimonialListing = async (search, pages, page) => {
    setSpinner(true);
    try {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/api/admin/testimonials${
            pages ? "?page=" + pages + `&size=${page > 10 ? page : 10}` : ""
          }${search ? `&search=` + search : ""}`,
          { headers: headers }
        )
        ?.then((res) => {
          if (res?.status >= 200) {
            setPaginationCount(res?.data?.data);
            setTestimonialData(
              res?.data?.data?.data.map((item, index) => {
                return [
                  {
                    id: item?.id,
                    value:
                      res?.data?.data?.from + index ? (
                        res?.data?.data?.from + index
                      ) : (
                        <span className="empty-data">-</span>
                      ),
                  },
                  {
                    id: item?.id,
                    value: item?.client_name ? (
                      item?.client_name
                    ) : (
                      <span className="empty-data">-</span>
                    ),
                  },
                  {
                    id: item?.id,
                    value: item?.company_name ? (
                      item?.company_name
                    ) : (
                      <span className="empty-data">-</span>
                    ),
                  },
                  {
                    id: item?.id,
                    value: item?.role ? item?.role : <span className="empty-data">-</span>,
                  },
                  {
                    id: item?.id,
                    className: "test-content",
                    value: item?.content ? (
                      item?.content.length > 100 ? (
                        `${item?.content?.substring(0, 100)} ...`
                      ) : (
                        item?.content
                      )
                    ) : (
                      <span className="empty-data">-</span>
                    ),
                  },
                  {
                    id: item?.id,
                    value: (
                      <>
                        <Link to={`/testimonials/testimonials-details/${item?.id}`}>
                          <img src="./assets/images/eye.svg" alt="" className="edit pointer" />
                        </Link>

                        <Link to={`/testimonials/edit-testimonials/${item?.id}`}>
                          <img src="./assets/images/edit.svg" alt="" className="edit" />
                        </Link>
                        <img
                          src="./assets/images/trash.svg"
                          alt=" "
                          className="edit pointer"
                          onClick={() => setTestDelete({ state: true, id: item?.id })}
                        />
                      </>
                    ),
                  },
                ];
              })
            );
            toast.success(res?.data?.message);
            setSpinner(false);
          }
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message);
          setSpinner(false);
        });
    } catch (error) {}
  };
  useEffect(() => {
    testimonialListing("", pages, "");
  }, [pages]);
  useEffect(() => {
    testimonialListing(searchEvent, 1, "");
    setPage(1);
  }, [searchEvent]);
  // ---- -------* listing searching  *------------
  const handleSearch = (data) => {
    setSearchEvent(data);
    // setPage()
  };
  // ---- delete api

  const deleteUserById = (id) => {
    setSpinner(true);
    axios
      .delete(`${process.env.REACT_APP_BASE_URL}/api/admin/testimonials/${id}`, {
        headers: headers,
      })
      ?.then((res) => {
        if (res?.status === 200) {
          //   toast.success(res?.data?.message);
          testimonialListing(searchEvent, pages, "");
          setSpinner(false);
          setTestDelete({ state: false });
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        setSpinner(false);
      });
  };
  return (
    <>
      <div className="title d-flex justify-content-between align-items-center">
        <h2>Testimonials</h2>
        <div className="d-flex search-dropdown">
          <div className="search-box">
            <img src="./assets/images/search.svg" alt="" />
            <input
              type="search"
              placeholder="Search Testimonials"
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div>
          <Link to={`/testimonials/add-testimonials`} className="primery-btn">
            <img src="./assets/images/plus.svg" alt="" /> Add{" "}
          </Link>
        </div>
      </div>
      <div className="content">
        <div className="content table-content">
          <Table
            heading={[
              { value: "S.No.", id: 1, width: "10%" },
              { value: "Company Name", id: 2, width: "15%" },
              { value: "Client Name", id: 3, width: "20%" },
              { value: "Role", id: 4, width: "10%" },
              { value: "Content", id: 5, width: "35%" },
              { value: "Action", id: 6, width: "10%" },
            ]}
            rows={testimonialData}
          />
          {testimonialData?.length === 0 && (
            <div className="alert-text no-data">
              <div className="error-data">
                <p className="font-size-1.5"> No data found</p>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* ---------- pagination -------------- */}
      <div className="dash_pagination col-sm-12 mt-5">
        <nav aria-label="Page navigation example">
          {paginationCount?.total > 0 && (
            <Pagination
              totalPages={paginationCount?.last_page}
              currentPage={paginationCount?.current_page}
              page={pages}
              setPage={(page) => setPage(page)}
            />
          )}
        </nav>
      </div>

      {/* ---- delete modal ----- */}
      <CommonModal
        show={testDelete.state}
        onHide={() =>
          setTestDelete({
            state: false,
          })
        }
        className="add-post-modal"
        header={<></>}
        body={
          <>
            <h3>Delete Testimonial</h3>
            <p>Are you sure you want to delete this Testimonial?</p>
            <div className="btnContainer-modal d-flex">
              <button className="primery-btn me-3" onClick={() => deleteUserById(testDelete?.id)}>
                Yes
              </button>
              <button
                className="secondry-btn"
                onClick={() =>
                  setTestDelete({
                    state: false,
                  })
                }
              >
                No
              </button>
            </div>
          </>
        }
      />
    </>
  );
}
