import React, { useContext, useEffect, useState } from "react";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios, { headers } from "../../http/axios";
import OtpInput from "react-otp-input";
import { GlobalContext } from "../../App";

const Verification = () => {
  const [otpValue, setOtp] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const { setSpinner } = useContext(GlobalContext);
  // --- time count ----
  const counter = Number(localStorage.getItem("counter"))
  const [countStart, setCounterStart] = useState(counter > 0 ? true : false);
  const [tempVar, setTempVar] = useState(1)

  /**
   * @method resendCode
   * @param {Event} event
   */

  // ----------------------- forget password api ------------------------
  const resendCode = (event) => {
    event.preventDefault();
    setSpinner(true);
    localStorage.setItem("counter", 60)
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/api/admin/password/forgot`, {
        email: location.state.email,
      })
      .then((res) => {
        setCounterStart(true)
        setSpinner(false);
      })
      .catch((err) => {
        setSpinner(false);
        toast.error(err?.response?.data?.message);
      });
  };
  /**
   * @method [isValid] to show the validation message
   * @returns Boolean
   */

  /**
   * @method [verificationOtp] use to verify the otp that has been recieved in email
   * @param {Event} event
   * @returns
   */

  const verificationOtp = (event) => {
    event.preventDefault();
    if (!otpValue || otpValue.length < 6) {
      toast.error("OTP is not valid.");
      return;
    }
    setSpinner(true);
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/api/admin/password/validate/token`,
        {
          email: location.state.email,
          token: otpValue,
        },
        {
          headers: headers,
        }
      )
      .then((res) => {
        setTimeout(() => {
          setSpinner(false);
          navigate("/reset_password", {
            state: { email: location?.state?.email, token: otpValue },
          });
        }, 0);
      })
      .catch((err) => {
        setSpinner(false);
        toast.error(err.response.data.message);
      });
  };
  // --------- timer------------ 
  useEffect(() => {
    setTempVar(0)
    if (countStart) {
      const interval = setInterval(() => {
        let a = counter - 1
        localStorage.setItem("counter", a)
        setTimeout(() => {
          setTempVar(1)
        }, 0);
        if (Number(counter) === 0) {
          setCounterStart(false)
          clearInterval(interval);
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [counter, countStart]);

  return (
    <>
      <div className="sign-info">
        <h2>Enter OTP</h2>
        <p>Please enter your code</p>
      </div>
      <form className="form" onSubmit={verificationOtp}>
        <div className="form-group">
          <label for>Enter OTP</label>
          <div className="otp otpContainer">
            <OtpInput
              inputStyle="pin-input"
              containerStyle="enterpin-area w-100"
              numInputs={6}
              value={otpValue}
              isInputNum={true}
              onChange={(value) => setOtp(value)}
              separator={<span> &nbsp;</span>}
              renderInput={(props) => <input {...props} />}
            />
          </div>
        </div>
        <div className="form-group">
          <button className="primery-btn w-100"> Verify & Proceed</button>
          <p className={`back-login ${countStart ? "disabled" : ""}`}>
            <Link to="/forget_password" onClick={resendCode} className={`${countStart ? "disabled pe-none" : ""}`} >
              <span >Resend OTP </span>&nbsp;
              {tempVar > 0 && ""}
              {countStart && "after" + " " + counter + " " + "sec"}
            </Link>
          </p>
        </div>
      </form >
    </>
  );
};
export default Verification;
