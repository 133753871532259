import React from 'react';

function Table({ heading, rows, loading, className,width }) {
    return (
        <>
            <div className={`responsive-table ${className ? className : ""}`}>
                <table className="">
                    <thead>
                        <tr>
                            {heading &&
                                heading?.map((headingData, index) => {
                                    return (
                                        <th width={headingData?.width}
                                            className={
                                                headingData?.className ? headingData?.className : ""
                                            }
                                            key={`head-${index}`}
                                        >
                                            {headingData.value}
                                        </th>
                                    );
                                })}
                        </tr></thead>
                    <tbody>
                        {rows?.length ? (
                            rows?.map((rowsData, index) => {
                                return (
                                    <tr key={`row-${index}`}>
                                        {rowsData.length &&
                                            rowsData.map((row, i) => {
                                                return <td key={`td-${i}`} className={row?.className}>{row?.value}</td>;
                                            })}
                                        {/* {loading && <Skeleton count={50} />} */}
                                    </tr>
                                );
                            })
                        ) :
                            //   ------------- if  no data ----------
                            <tr>
                                <td>
                                    <div className="alert-text no-data">
                                        <div className="error-data">
                                            <p className='font-size-1.5'> No data found</p>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default Table;