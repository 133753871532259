import React from "react";
import { Line } from "react-chartjs-2";
import Chart from "chart.js/auto";

const MonthlyChart = ({ graphData }) => {
  const rawData = {
    silver: [
      {
        count: 1,
        date: "2023-07-14",
      },
      {
        count: 1,
        date: "2023-07-17",
      },
      {
        count: 2,
        date: "2023-07-18",
      },
      {
        count: 1,
        date: "2023-07-19",
      },
      {
        count: 3,
        date: "2023-07-24",
      },
      {
        count: 3,
        date: "2023-07-25",
      },
    ],
    gold: [
      {
        count: 2,
        date: "2023-07-13",
      },
      {
        count: 5,
        date: "2023-07-14",
      },
      {
        count: 1,
        date: "2023-07-17",
      },
      {
        count: 1,
        date: "2023-07-18",
      },
    ],
    platinum: [
      {
        count: 1,
        date: "2023-07-17",
      },
      {
        count: 1,
        date: "2023-07-17",
      },
      {
        count: 1,
        date: "2023-07-18",
      },
      {
        count: 3,
        date: "2023-07-24",
      },
      {
        count: 3,
        date: "2023-07-25",
      },
    ],
  };

  const colorLine = (item) => {
    if (item === "gold") {
      return "#E0BF65";
    } else if (item === "platinum") {
      return "#AEA18A";
    } else {
      return "#9C9C9C";
    }
  };
  // Extract data for each metal
  const metals = Object.keys(graphData);
  const datasets = metals.map((metal) => {
    // Sort the data by date for each metal
    const sortedData = graphData[metal].sort((a, b) => {
      return new Date(a.date) - new Date(b.date);
    });

    // Get the current date and subtract 30 days to get the start date for the chart
    const currentDate = new Date();
    const startDate = new Date();
    startDate.setDate(currentDate.getDate() - 30);

    // Map data to each of the last 30 days (including the current day)
    const countsWeekly = new Array(5).fill(0); // Initialize array with 0 counts for each week

    sortedData.forEach((item) => {
      const date = new Date(item.date);
      if (date >= startDate && date <= currentDate) {
        const weekNumber = Math.ceil(
          (date - startDate) / (7 * 24 * 60 * 60 * 1000)
        );
        countsWeekly[weekNumber - 1] += item.count;
      }
    });

    return {
      label: metal,
      data: countsWeekly,
      borderColor: colorLine(metal),
      fill: false,
    };
  });

  // Labels for the x-axis (Week 1, Week 2, Week 3, Week 4, Week 5)
  const labels = ["Week 1", "Week 2", "Week 3", "Week 4", "Week 5"];

  // Chart.js configuration object
  const chartData = {
    labels: labels,
    datasets: datasets.map((dataset) => ({
      ...dataset,
      fill: true, // Fill the area under the line
      pointRadius: 0, // Hide the data points
      tension: 0.4, // Set tension for cubic Bézier interpolation (0 = straight lines, 1 = full curves)
    })),
  };

  // Chart.js options with animations
  // Chart.js options with grow animation
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
      },
    },
    animation: {
      duration: 1000, // Duration of grow animation in milliseconds
      easing: "easeInOutQuart", // Easing function to control the animation speed
      animateRotate: false, // Disable rotation animation for pie/doughnut charts
      animateScale: true, // Enable scale animation for scale-based (non-pie) charts
    },
  };

  return (
    <div style={{ maxWidth: "100%", margin: "0 auto" }} className="graph">
      <Line data={chartData} options={chartOptions} />
    </div>
  );
};

export default MonthlyChart;
