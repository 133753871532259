import React, { useEffect, useState, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import BackNavigate from "../common-Component/BackNavigate";
import Select from "react-select";
import axios, { headers } from "../../http/axios";
import { toast } from "react-toastify";
import { GlobalContext } from "../../App";
import { useNavigate } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
const options = [
  { value: "Voice Search Optimization", label: "Voice Search Optimization" },
  { value: "Local SEO", label: "Local SEO" },
  { value: "E-A-T", label: "E-A-T" },
  { value: "Visual Search", label: "Visual Search" },
];
const MAX_IMAGE_SIZE = 1024 * 1024; // 1MB in bytes

export function AddBlog() {
  const navigate = useNavigate();
  const { setSpinner } = useContext(GlobalContext);
  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const [previewImage, setPreviewImage] = useState(null);
  const [previewThumbnailImage, setPreviewThumbnailImage] = useState(null);

  const getBannerImage = watch("bannerImage")?.[0];
  const getThumbnailImage = watch("thumbnailImage")?.[0];

  const handleFileUpload = (event, setImage) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setImage(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (getBannerImage) {
      handleFileUpload({ target: { files: [getBannerImage] } }, setPreviewImage);
    }
    if (getThumbnailImage) {
      handleFileUpload({ target: { files: [getThumbnailImage] } }, setPreviewThumbnailImage);
    }
  }, [getBannerImage, getThumbnailImage]);

  const onSubmit = async (data) => {
    setSpinner(true);
    const filterTagValues = data?.tags?.map((ele) => {
      return ele.value;
    });

    try {
      const formData = new FormData();
      formData.append("image", data.bannerImage[0]);

      const bannerResponse = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/upload-image`,
        formData
      );

      console.log("Banner response", bannerResponse.data);
      const bannerKey = bannerResponse.data.image;

      const formDataForThumbnail = new FormData();
      formDataForThumbnail.append("image", data.thumbnailImage[0]);

      const thumbnailResponse = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/upload-image`,
        formDataForThumbnail
      );

      console.log("Thumbnail response", thumbnailResponse.data);
      const thumbnailKey = thumbnailResponse.data.image;

      const finalResponse = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/admin/blogs`,
        {
          title: data.title,
          content: data.content,
          banner_image: bannerKey,
          thumbnail_image: thumbnailKey,
          tags: filterTagValues ? filterTagValues : [],
        },
        {
          headers: headers,
        }
      );

      console.log("Final response", finalResponse.data);
      navigate("/blogs");
      toast.success(finalResponse.data.message);
    } catch (error) {
      console.error("Error occurred while uploading images:", error);
      toast.error(error.response.data.message);
    } finally {
      setSpinner(false);
    }
  };

  const handleFileReset = (file) => {
    if (file === "previewImage") {
      setPreviewImage("");
      setValue("bannerImage", "");
    } else {
      setPreviewThumbnailImage("");
      setValue("thumbnailImage", "");
    }
  };
  return (
    <>
      <div className="title d-flex justify-content-between align-items-center">
        <h2>
          <BackNavigate /> Add Blog
        </h2>
      </div>
      <div className="content">
        <form className="testimonials-form add-blog-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <label htmlFor="title">
              Title <span className="text-danger"> *</span>
            </label>
            <input
              name="title"
              type="text"
              placeholder="Enter Title"
              id="title"
              {...register("title", {
                required: "Title is required",
                validate: (value) => value.trim().length > 0 || "Title is required",
              })}
            />
            {errors.title && <span style={{ color: "red" }}>{errors.title.message}</span>}
          </div>
          {/* <div className="form-group">
            <label htmlFor="content">
              Content <span className="text-danger"> *</span>
            </label>
            <input
              name="content"
              type="text"
              placeholder="Enter Content"
              id="content"
              {...register("content", {
                required: "Content is required",
                validate: (value) => value.trim().length > 0 || "Content is required",
              })}
            />
            {errors.content && <span style={{ color: "red" }}>{errors.content.message}</span>}
          </div> */}
     
          <div className="form-group">
            <label htmlFor="content">
              Content <span className="text-danger"> *</span>
            </label>
            <Controller
              control={control}
              name="content"
              rules={{
                required: "Content is required",
                validate: (value) => value.trim().length > 0 || "Content is required",
              }}
              render={({ field }) => (
                <CKEditor
                  editor={ClassicEditor}
                  data={field.value}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    field.onChange(data);
                  }}
                />
              )}
            />
            {errors.content && <span style={{ color: "red" }}>{errors.content.message}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="tags">
              Add Tags
              <span>(Maximum limit is 5)</span> {/* <span className="text-danger"> *</span> */}
            </label>
            <Controller
              control={control}
              name="tags"
              rules={{
                validate: (value) => !value || value.length <= 5 || "Maximum limit exceeded",
              }}
              render={({ field }) => <Select options={options} isMulti {...field} />}
            />
            {errors.tags && <span style={{ color: "red" }}>{errors.tags.message}</span>}
          </div>
          <div className="d-flex">
            <div className="form-group">
              <label htmlFor="banner-image">
                Banner Image <span className="text-danger"> *</span>
              </label>
              <div className="set-cross-icon">
                <label htmlFor="upload" className="upload_file">
                  <input
                    accept=".jpg,.jpeg,.png"
                    id="upload"
                    type="file"
                    className="d-none"
                    {...register("bannerImage", {
                      required: "Banner Image is required",
                      validate: (value) => {
                        if (!value[0]) return "Banner Image is required";
                        const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
                        if (!allowedTypes.includes(value[0].type)) {
                          return "Only images (JPEG, PNG) are allowed";
                        }
                        if (value[0].size > MAX_IMAGE_SIZE) {
                          return "Image size should not exceed 1MB";
                        }
                        return true;
                      },
                    })}
                  />
                  <img
                    src={previewImage ? previewImage : "./assets/images/upload-banner.svg"}
                    alt="banner"
                  />
                </label>
                {previewImage && (
                  <span
                    className="cross"
                    onClick={() => {
                      handleFileReset("previewImage");
                    }}
                  >
                    <img src="assets/images/cross.svg" alt="" />
                  </span>
                )}
                {errors.bannerImage && (
                  <span style={{ color: "red" }}>{errors?.bannerImage?.message}</span>
                )}
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="thumbnail-image">
                Thumbnail Image <span className="text-danger"> *</span>
              </label>
              <div className="set-cross-icon">
                <label htmlFor="upload1" className="upload_file">
                  <input
                    accept=".jpg,.jpeg,.png"
                    id="upload1"
                    type="file"
                    className="d-none"
                    {...register("thumbnailImage", {
                      required: "Thumbnail Image is required",
                      validate: (value) => {
                        if (!value[0]) return "Banner Image is required";
                        const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
                        if (!allowedTypes.includes(value[0].type)) {
                          return "Only images (JPEG, PNG) are allowed";
                        }
                        if (value[0].size > MAX_IMAGE_SIZE) {
                          return "Image size should not exceed 1MB";
                        }
                        return true;
                      },
                    })}
                  />
                  <img
                    src={
                      previewThumbnailImage
                        ? previewThumbnailImage
                        : "./assets/images/upload-thumb.svg"
                    }
                    alt=" "
                  />
                </label>
                {previewThumbnailImage && (
                  <span
                    className="cross"
                    onClick={() => {
                      handleFileReset("thumbnailImage");
                    }}
                  >
                    <img src="assets/images/cross.svg" alt="" />
                  </span>
                )}
                {errors.thumbnailImage && (
                  <span style={{ color: "red" }}>{errors?.thumbnailImage?.message}</span>
                )}
              </div>
            </div>
          </div>
          <div className="form-group">
            <button type="submit" className="primery-btn">
              Save
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
