import React, { useContext } from "react";

import { Link, NavLink } from "react-router-dom";
import { GlobalContext } from "../../App";

const admin = [
  {
    id: 1,
    url: "/dashboard",
    label: "Dashboard",
    className1: "hover_active_img",
    className2: "active_img",
    img1: "./assets/images/dashboard.svg",
    alt1: "dashboard-nav-icon",
    alt2: "security_safe_white",
  },
  {
    id: 2,
    url: "/users",
    label: "Users",
    className1: "hover_active_img",
    className2: "active_img",
    img1: "./assets/images/user.svg",
    alt1: "claims_blue",
    alt2: "claims_white",
  },

  {
    id: 3,
    url: "/testimonials",
    label: "Testimonials",
    className1: "hover_active_img",
    className2: "active_img",
    img1: "./assets/images/testimonial.png",
    alt1: "users_blue",
    alt2: "users_white",
  },
  {
    id: 4,
    url: "/blogs",
    label: "Blogs",
    className1: "hover_active_img",
    className2: "active_img",
    img1: "./assets/images/blog.svg",
    // disabled: "disabled",
    alt1: "claims_blue",
    alt2: "claims_white",
  },
  {
    id: 5,
    url: "/projects",
    label: "Projects",
    className1: "hover_active_img",
    className2: "active_img",
    img1: "./assets/images/project.svg",
    // disabled: "disabled",
    alt1: "claims_blue",
    alt2: "claims_white",
  },
  {
    id: 6,
    url: "/change-password",
    label: "Change Password",
    className1: "hover_active_img",
    className2: "active_img",
    img1: "./assets/images/key.svg",
    alt1: "claims_blue",
    alt2: "claims_white",
  },
];

const SideBar = () => {
  const { settoggleBtn, toggleBtn } = useContext(GlobalContext);
  return (
    <div className={`sidebar ${toggleBtn === true ? "sidebar-toggle" : ""}`}>
      <div className="logo">
        <img src="./assets/images/logo.svg" alt="" />
      </div>
      <ul>
        {/* Admin section start */}
        {admin?.map((data, index) => {
          // --------------- data Destructuring
          const { id, url, label, className1, img1, alt1, disabled } = data;
          return (
            <li key={id} className={`navActive`}>
              <NavLink
                to={url}
                key={`sidebar-${index}`}
                className={` ${disabled}`}
                onClick={() => {
                  settoggleBtn(false);
                }}
              >
                <img src={img1} alt={alt1} className={className1} />
                {label}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
export default SideBar;
