import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Button } from "bootstrap";
import CommonModal from "../common-Component/modal/CommonModal";
import CommonInput from "../common-Component/form/CommonInput";
import { GlobalContext } from "../../App";
import { useForm } from "react-hook-form";
import axios, { headers } from "../../http/axios";
import { toast } from "react-toastify";
import Table from "../common-Component/table/Table";
import moment from "moment";
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import Pagination from "../common-Component/Pagination";
import { Spinner } from "react-bootstrap";
import BackNavigate from "../common-Component/BackNavigate";

export function UserPost() {
  const [addPost, setAddPost] = useState(false);
  const { setSpinner } = useContext(GlobalContext);
  const [postList, setPostList] = useState();
  const navigate = useNavigate();
  // ---- * pagination
  const [paginationCount, setPaginationCount] = useState({});
  const [pages, setPage] = useState(1);
  //  delete state 
  const [postDelete, setPostDelete] = useState({
    state: false,
    id: "",
  })

  function capitalizeAfterComma(inputString) {
    return inputString.replace(/(?:^|, )(\w)/g, function (match, letter) {
      return match.toUpperCase();
    });
  }





  const dummyLinks = [{ platform: "facebook", img: "./assets/images/facebook2.svg", },
  { platform: "instagram", img: "./assets/images/instagram2.svg", },
  { platform: "twitter", img: "./assets/images/twitter.svg", },
  { platform: "linkedin", img: "./assets/images/linkdin2.svg", },]

  const [links, setLinks] = useState(dummyLinks)
  const [postId, setPostId] = useState()
  const [getDate, setDate] = useState();
  const editData = (data) => {
    setPostId(data?.id)
    setDate(data?.date)
    setLinks(dummyLinks?.map((item) => {
      data?.social_links?.map(a => {
        if (a?.platform === item?.platform) {
          // if (a.link.match(/^(http(s)?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.){1,}[a-zA-Z0-9]{2,}(\S*)?$/)) {
          //   item.error= "Please enter valid url."
          // }
          item.link = a.link;
          item.check = true
        }
      })
      return item
    }))
    setAddPost(true)
  }
  // ---- -------* listing api *------------
  const postListingApi = async (pages, page) => {
    setSpinner(true);
    try {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/api/admin/posts${pages ? "?page=" + pages + `&size=${page > 10 ? page : 10}` : ""
          }`,
          { headers: headers }
        )
        ?.then((res) => {
          if (res?.status >= 200) {
            setPaginationCount(res?.data?.data);
            setPostList(
              res?.data?.data?.data?.map((item, index) => {
                return [
                  {
                    id: item?.id,
                    value:
                      res?.data?.data?.from + index ? (
                        res?.data?.data?.from + index
                      ) : (
                        <span className="empty-data">-</span>
                      ),
                  },
                  {
                    id: item?.id,
                    value: item?.date ? (
                      moment(item?.date).format("DD/MM/YYYY")
                    ) : (
                      <span className="empty-data">-</span>
                    ),
                  },
                  {
                    id: item?.id,
                    value: item?.social_links?.length ? (
                      item?.social_links?.map((itm, i) => { return (
                        <>
                          {i ? ", " : ""}
                          {capitalizeAfterComma(itm?.platform)}
                        </>
                      ); })
                    ) : (
                      <span className="empty-data">-</span>
                    ),
                  },
                  {
                    id: item?.id,
                    className: "post-url",
                    value: item?.social_links?.length ? (
                      item?.social_links?.map((itm, i) => {
                        return <>
                          {i ? <span>, </span> : ""}
                          <a href={itm?.link.includes("https://") ? itm?.link : "https://" + itm?.link} target="_blank" rel="noreferrer">{itm?.link}</a>
                        </>
                      })
                    ) : (
                      <span className="empty-data">-</span>
                    ),
                  },
                  {
                    id: item?.id,
                    value: (
                      <>
                        <Link to={`/users/user-post-details/${item?.id}`}>
                          <img
                            src="./assets/images/eye.svg"
                            alt=""
                            className="eye pointer"
                          />
                        </Link>
                        <img
                          src="./assets/images/edit.svg"
                          alt=" "
                          className="edit pointer"
                          onClick={() => { editData(item) }}
                        />
                        <img
                          src="./assets/images/trash.svg"
                          alt=" "
                          className="edit pointer"
                          onClick={() =>
                            setPostDelete({ state: true, id: item?.id })
                          }
                        />
                      </>
                    ),
                  },
                ];
              })
            );
            setSpinner(false);
          }
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message);
          setSpinner(false);
        });
    } catch (error) { }
  };

  useEffect(() => {
    postListingApi(pages);
  }, [pages]);
  // --------------- * set post data in edit form api 
  const postUpdateApi = async () => {
    setSpinner(true);
    let body = {
      date: moment(getDate).format("YYYY-MM-DD HH:MM:ss"),
      links: links?.filter(a => a.check && a.link)?.map(a => ({ platform: a.platform, ...(a?.link ? { link: a?.link } : { link: "" }) }))
    }
    // ------ * for *add(post)* & *editData(put)*
    let method = postId ? axios.put : axios.post
    try {
      method(`${process.env.REACT_APP_BASE_URL}/api/admin/posts${postId ? "/" + postId : ""}`, body, { headers: headers })
        ?.then((res) => {
          if (res?.status >= 200) {
            postListingApi();
            // toast.success(res?.data?.message);
            setSpinner(false);
            setAddPost(false)
          }
        }).catch((error) => {
          toast.error(error?.response?.data?.message);
          setSpinner(false);
        })
    } catch (error) {
    }
  }
  // ---- clear form data ---
  const clearState = (cond) => {
    if (!cond) {
      setAddPost(false)
    }

    setLinks(dummyLinks)
    setDate()
    setPostId()
  }
  // ---- delete api 
  const deleteUserById = (id) => {
    setSpinner(true);
    axios
      .delete(`${process.env.REACT_APP_BASE_URL}/api/admin/posts/${id}`, {
        headers: headers,
      })
      ?.then((res) => {
        if (res?.status === 200) {
          //   toast.success(res?.data?.message);
          postListingApi(pages, "");
          setSpinner(false);
          setPostDelete({ state: false });
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        setSpinner(false);
      });
  };
  // ------ * add post 
  // const addNewPost = async () => {
  //   setSpinner(true);
  //   let body = {
  //     date: moment(getDate).format("YYYY-MM-DD HH:MM:SS"),
  //     links: links?.filter(a => a.check)?.map(a => ({ platform: a.platform, ...(a?.link ? { link: a?.link } : { link: "" }) }))
  //   }
  //   try {
  //     axios.post(`${process.env.REACT_APP_BASE_URL}/api/admin/posts`, body, { headers: headers })
  //       ?.then((res) => {
  //         if (res?.status >= 200) {
  //           postListingApi();
  //           toast.success(res?.data?.message);
  //           setSpinner(false);
  //           setAddPost(false)
  //         }
  //       }).catch((error) => {
  //         toast.error(error?.response?.data?.message);
  //         setSpinner(false);
  //       })
  //   } catch (error) {
  //   }
  // }
  // ------------- *disable btn until selete the platform
  const disableBtnFunction = links?.filter(a => a?.check).map(itm => {
    if (itm?.check && itm?.link && !itm.error) {
      return true
    } else {
      return false
    }
  })?.includes(false)


  return (
    <>
      <div className="title d-flex justify-content-between align-items-center">
        <h2>
          <BackNavigate />
          Users/ Posts
        </h2>
        <div className="d-flex search-dropdown">
          {/* <div className="search-box">
                        <img src="./assets/images/search.svg" alt=" " />
                        <input type="search" placeholder="Search Users" />
                    </div> */}
          <button className="primery-btn" onClick={() => { setAddPost(true); clearState("add") }}>
            <img src="./assets/images/plus.svg" alt=" " /> Add Post
          </button>
        </div>
      </div>
      <div className="content">
        <div className="content table-content">
          <Table
            heading={[
              { value: "S.No.", id: 1, width: "10%" },
              { value: "Date Created", id: 2, width: "20%" },
              { value: "Platform", id: 3, width: "30%" },
              { value: "Link", id: 4, width: "30%" },
              { value: "Action", id: 5, width: "10%" },
            ]}
            rows={postList}
          />
        </div>
      </div>

      {/* ---------- pagination -------------- */}
      <div className="dash_pagination col-sm-12 mt-5">
        <nav aria-label="Page navigation example">
          {paginationCount?.total > 10 && (
            <Pagination
              totalPages={paginationCount?.last_page}
              currentPage={paginationCount?.current_page}
              page={pages}
              setPage={(page) => setPage(page)}
            />
          )}
        </nav>
      </div>
      {/* ---- add post modal ----- */}
      <CommonModal
        show={addPost}
        onHide={clearState}
        className="add-post-modal"

        body={
          <>
            <>
              <h3>{postId ? "Edit" : "Add"}  Post</h3>
              <p>Please enter post details here.</p>
            </>
            {/* --- link inputs --- */}
            <form >
              <div className="form-group">
                {/* {date} */}
                <DatePicker value={getDate ? new Date(getDate) : (new Date())} onChange={(e) => setDate(e)} clearIcon={null} />
              </div>
              <div className="form-group form-set">
                <label>Add Social Links</label>
                {links?.map((item, index) => {
                  return (
                    <div className="urlbox">
                      <div className="d-flex  ">
                        <React.Fragment id={index}>
                          <CommonInput
                            type="checkbox"
                            id="fb"
                            inputclassName="checkbox chassis_make"
                            value={item.link}
                            checkBox
                            checked={item?.check}
                            onChange={(e) => setLinks(prev => prev?.map((itm) => {
                              if (itm?.platform === item?.platform) {
                                itm.check = e.target.checked

                              }
                              return itm
                            }))}
                          />
                          <img src={item?.img} alt=" " />
                          <CommonInput type="text" style={{ flex: "1" }}
                            className={`mx-w-100 ${!item.check ? "disabled" : ""}`}
                            inputClass={`${!item.check ? " pe-none " : "heighlight-border"}`}
                            placeholder={!item.check ? "Add url" : "Add url"}
                            disable={!item.check}
                            value={item.link}
                            onChange={(e) => setLinks(prev => prev?.map((itm) => {
                              if (itm?.platform === item?.platform) {
                                if (e.target.value.match(/^(https?:\/\/)?(www\.)?[^\s/$.?#]+\.[^\s]*\.com[^\s]*$/i)) {
                                  itm.link = e.target.value
                                  itm.error = ""
                                }
                                else {
                                  itm.link = e.target.value
                                  itm.error = "Please enter valid url."
                                  // alert("Error")
                                }

                              }
                              return itm
                            }))} />
                        </React.Fragment>
                      </div>

                      {item?.error
                        && <p role="alert" className="alert-msg text-danger">
                          {item.error}
                        </p>}
                    </div>
                  )
                })}
              </div>
              {/* ---submit btn -- */}
              <div className="modal-footer">
                <button
                  type="button"
                  className={`primery-btn ${links?.map((item) => { return !item?.check }) ? "disable" : ""}`}
                  data-bs-dismiss="modal"
                  onClick={postUpdateApi}
                  disabled={disableBtnFunction}
                >
                  Submit
                </button>
              </div>
            </form>
          </>
        }
      />

      {/* ---- delete modal ----- */}
      <CommonModal
        show={postDelete.state}
        onHide={() =>
          setPostDelete({
            state: false,
          })
        }
        className="add-post-modal"
        header={<></>}
        body={
          <>
            <h3>Delete Post</h3>
            <p>Are you sure you want to delete this  post?</p>
            <div className="btnContainer-modal d-flex">
              <button
                className="primery-btn me-3"
                onClick={() => deleteUserById(postDelete?.id)}
              >
                Yes
              </button>
              <button
                className="secondry-btn"
                onClick={() =>
                  setPostDelete({
                    state: false,
                  })
                }
              >
                No
              </button>
            </div>
          </>
        }
      />
    </>
  );
}
