import React, { useEffect, useState, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import BackNavigate from "../common-Component/BackNavigate";
import axios, { headers } from "../../http/axios";
import { GlobalContext } from "../../App";
import { toast } from "react-toastify";
export function BlogDetails() {
  const { id } = useParams();
  const [blogDetails, setBlogDetails] = useState();
  const { setSpinner } = useContext(GlobalContext);
  useEffect(() => {
    setSpinner(true);
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/admin/blogs/${id}`, {
        headers: headers,
      })
      .then((response) => {
        console.log("response ", response?.data?.data);
        setBlogDetails(response?.data?.data);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      })
      .finally(() => {
        setSpinner(false);
      });
  }, [id]);

  return (
    <>
      <div className="title d-flex justify-content-between align-items-center">
        <h2>
          <BackNavigate />
          Blog Details
        </h2>
      </div>
      <div className="content">
        <form className="testimonials-form add-blog-form">
          <div className="form-group">
            <b>Title</b>
            <p>{blogDetails?.title}</p>
          </div>
          <div className="form-group">
            <b>Content</b>
            <p dangerouslySetInnerHTML={{ __html: blogDetails?.content }} />
          </div>
          {blogDetails?.tags?.length > 0 && blogDetails?.tags[0] !== "" && (
            <div className="form-group">
              <b>Tags</b>
              {blogDetails?.tags?.length > 0 && (
                <div className="tag-name">
                  {blogDetails?.tags?.map((ele) => {
                    return <span>{ele}</span>;
                  })}
                </div>
              )}
            </div>
          )}
          {blogDetails?.banner_image && (
            <div className="form-group">
              <b>Banner Image</b>
              <img
                src={`${process.env.REACT_APP_MEDIA_URL}/${blogDetails?.banner_image}`}
                alt="banner"
              />
            </div>
          )}
          {blogDetails?.thumbnail_image && (
            <div className="form-group">
              <b>Thumbnail Image</b>
              <img
                src={`${process.env.REACT_APP_MEDIA_URL}/${blogDetails?.thumbnail_image}`}
                alt="thumbnail"
              />
            </div>
          )}
        </form>
      </div>
    </>
  );
}
