import axios from "axios";
import { toast } from "react-toastify";
import Auth from "../auth/Auth";

const getHeaders = function () {
  const token = Auth.token() || "";
  if (token) {
    return {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
  }

  return {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
};

const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

// Add a request interceptor
apiInstance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    // config.headers = { ...getHeaders() };

    return config;
  },
  function (error) {
    // Do something with request error
    toast.error(error.response.data.message);
    return Promise.reject(error);
  }
);

// Add a response interceptor
apiInstance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // console.log("axios---------------",response);
    // toast.success(response.data.message);
    return {
      data: response.data,
      status: response.status,
    };
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    if (error.response.status === 401) {
      // Auth.logout();
      // window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

export default apiInstance;

export const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
  ...(Auth.token() && {
    Authorization: `Bearer ${Auth.token() || ""}`,
  }),
};

export const uploadHeaders = {
  Accept: "multipart/form-data",
  "Content-Type": "multipart/form-data",
  Authorization: `Bearer ${Auth.token()}`,
};
