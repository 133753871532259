import React, { useEffect, useRef, useState, useContext } from "react";
import CommonInput from "../common-Component/form/CommonInput";
import axios, { headers } from "../../http/axios";
import { GlobalContext } from "../../App";
import WeeklyChart from "./charts/WeeklyChart";
import MonthlyChart from "./charts/MonthlyChart";
import DailyChart from "./charts/DailyChart";

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState();
  const { setSpinner } = useContext(GlobalContext);
  const [radioBtnValue, setRadioBtnValue] = useState("");
  const [graphData, setGraphData] = useState();

  useEffect(() => {
    getDashboardDataCount();
    setRadioBtnValue("daily");
  }, []);

  const getDashboardDataCount = () => {
    setSpinner(true);
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/admin/dashboard`, {
        headers: headers,
      })
      .then((response) => {
        console.log("response ", response.data.data);
        setDashboardData(response.data.data);
      })
      .finally(() => {
        setSpinner(false);
      });
  };

  const handleGraphData = (e) => {
    console.log(e.target.value);
    setRadioBtnValue(e.target.value);
  };

  useEffect(() => {
    if (radioBtnValue) {
      getGraphData();
    }
  }, [radioBtnValue]);

  const getGraphData = () => {
    setSpinner(true);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/admin/dashboard/graph?filter=${radioBtnValue}`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        console.log("response ssss", response.data.data);
        setGraphData(response.data.data);
      })
      .finally(() => {
        setSpinner(false);
      });
  };
  return (
    <>
      <div className="title">
        <h2>Dashboard</h2>
      </div>
      <div className="content">
        <div className="sale-count">
          <div className="sliver dasboard_slvr">
            <span>
              <img src="./assets/images/sliver.svg" alt="" /> Silver
            </span>
            <b>{dashboardData?.silver_count}</b>
          </div>
          <div className="sliver dasboard_gold">
            <span>
              <img src="./assets/images/gold.svg" alt="" /> Gold
            </span>
            <b>{dashboardData?.gold_count}</b>
          </div>
          <div className="sliver dasboard_pletinum">
            <span>
              <img src="./assets/images/platinum.svg" alt="" /> Platinum
            </span>
            <b>{dashboardData?.platinum_count}</b>
          </div>
        </div>
        <div className="inner-title">
          <h3>Subscribers</h3>
        </div>

        <div className="d-flex graph-box">
          <div className="graph">
            <div className="slelect-day">
              <CommonInput
                onChange={handleGraphData}
                type="radio"
                label="Daily"
                className="me-2 checkbox reverse-label"
                id="14"
                value="daily"
                checkBox
                name="sub"
                defaultChecked={true}
              />
              {/* <input
                  type="checkbox"
                  id="14"
                  value="MISUBISHI / FUSO"
                  className="checkbox chassis_make"
                /> */}
              <CommonInput
                onChange={handleGraphData}
                type="radio"
                label="Weekly"
                className="me-2 checkbox reverse-label"
                id="15"
                name="sub"
                value="weekly"
                checkBox
              />

              <CommonInput
                onChange={handleGraphData}
                type="radio"
                label="Monthly"
                name="sub"
                className="me-2 checkbox reverse-label"
                id="16"
                value="monthly"
                checkBox
              />
            </div>
            {/* <img src="./assets/images/graph.png" alt="" /> */}
            {radioBtnValue === "daily" && graphData && (
              <DailyChart graphData={graphData} />
            )}
            {radioBtnValue === "weekly" && graphData && (
              <WeeklyChart graphData={graphData} />
            )}
            {radioBtnValue === "monthly" && graphData && (
              <MonthlyChart graphData={graphData} />
            )}
            <div className="graph-sliver">
              <img src="assets/images/sliver.png" alt="" />
            </div>
          </div>
          <div className="graph-ratio">
            <div
              className="total-user"
              style={{
                backgroundImage: `url(./assets/images/t-u.svg)`,
              }}
            >
              <span>Total Users</span>
              <b>{dashboardData?.users_count}</b>
            </div>
            <div
              className="total-user"
              style={{
                backgroundImage: `url(./assets/images/test-bg.svg)`,
              }}
            >
              <span>Testimonials</span>
              <b>{dashboardData?.testimonials_count}</b>
            </div>
            <div
              className="total-user"
              style={{
                backgroundImage: ` url(./assets/images/blog-bg.svg)`,
              }}
            >
              <span>Blogs</span>
              <b>{dashboardData?.blogs_count}</b>
            </div>
            <div
              className="total-user"
              style={{
                backgroundImage: ` url(./assets/images/project-bg.svg)`,
              }}
            >
              <span>Projects</span>
              <b>{dashboardData?.projects_count}</b>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
