import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios, { headers } from "../../http/axios";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../App";
import { toast } from "react-toastify";
import CommonInput from "../common-Component/form/CommonInput";
const Forget = () => {
  const navigate = useNavigate();
  const { setSpinner } = useContext(GlobalContext);
  // --- time count ----
  const counter = Number(localStorage.getItem("counter"))
  const [countStart, setCounterStart] = useState(counter > 0 ? true : false);
  const [tempVar, setTempVar] = useState(1)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  // ----------------------- forget password api ------------------------
  const forgetPassHandler = (data, e) => {
    data.role = "admin";
    e.preventDefault()
    setSpinner(true);
    axios.post(`${process.env.REACT_APP_BASE_URL}/api/admin/password/forgot`, data, { headers: headers })?.then((res) => {
      if (res?.status === 200) {
        localStorage.setItem("counter", 60)
        setSpinner(false);
        navigate("/verification_code", { state: { email: data.email } });
      }
    }).catch((error) => {
      toast.error(error?.response?.data?.message);
      setSpinner(false);
    })
  }
  // --------- timer------------ 
  useEffect(() => {
    setTempVar(0)
    if (countStart) {
      const interval = setInterval(() => {
        let a = counter - 1
        localStorage.setItem("counter", a)
        setTimeout(() => {
          setTempVar(1)
        }, 0);
        if (Number(counter) === 0) {
          setCounterStart(false)
          clearInterval(interval);
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [counter, countStart]);
  return (
    <>
      <div className="sign-info">
        <h2>Forgot Password?</h2>
        <p>Please enter your email ID</p>
      </div>
      <form className="form" onSubmit={handleSubmit(forgetPassHandler)}>
        {/* --email input--- */}
        <div className="form-group">
          <CommonInput
            id="email"
            type="email"
            label="Email"
            placeholder="Enter email"
            inputclassName={`${errors.email?.type ? " border-danger" : ""}`}
            register={{
              ...register("email", {
                required: true,
                pattern: /\S+@\S+\.\S+/,
              })
            }}
          />
          {errors.email?.type === "required" && (
            <p role="alert" className="alert-msg text-danger">
              Email is required
            </p>
          )}
          {errors.email?.type === "pattern" && (
            <p role="alert" className="text-danger">
              Incorrect email format
            </p>
          )}
          <img src="./assets/images/atrate.svg" alt=" " className="eye-open" />
        </div>
        <div className="form-group">
          <div className={` ${countStart ? "disabled" : ""}`}>
            <button className={`primery-btn w-100 ${countStart ? " pe-none" : ""}`} disabled={countStart ? true : ""}>Submit</button>
          </div>
          {countStart && <p className="theme-color text-center pt-3  font-size-18">
            {tempVar > 0 && ""}
            {"Resend code  after" + " " + counter + " " + "sec"}
          </p>}
          <p className="back-login">
            Back to <Link to="/">Login</Link>
          </p>
        </div>
      </form >
    </>
  );
};
export default Forget;
