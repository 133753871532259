import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import BackNavigate from "../common-Component/BackNavigate";
import axios, { headers } from "../../http/axios";
import { GlobalContext } from "../../App";
import { toast } from "react-toastify";

export function ProjectDetails() {
  const { id } = useParams();
  const [projectDetails, setProjectDetails] = useState();
  const { setSpinner } = useContext(GlobalContext);
  useEffect(() => {
    setSpinner(true);
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/admin/projects/${id}`, {
        headers: headers,
      })
      .then((response) => {
        console.log("response ", response?.data?.data);
        setProjectDetails(response?.data?.data);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      })
      .finally(() => {
        setSpinner(false);
      });
  }, [id]);

  return (
    <>
      <div className="title d-flex justify-content-between align-items-center">
        <h2>
          <BackNavigate /> Project Details
        </h2>
      </div>
      <div className="content">
        <form className="testimonials-form add-blog-form">
          <div className="form-group">
            <b>Title</b>
            <p>{projectDetails?.title}</p>
          </div>
          <div className="form-group">
            <b>Description</b>
            <p>{projectDetails?.description}</p>
          </div>
          <div className="d-flex">
            <div className="form-group">
              <b>Image</b>
              {projectDetails?.image && (
                <img
                  s
                  src={`${process.env.REACT_APP_MEDIA_URL}/${projectDetails?.image}`}
                  alt="banner"
                />
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
