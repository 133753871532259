import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CommonInput from "../common-Component/form/CommonInput";
import { useForm } from "react-hook-form";
import axios from "axios";
import { headers } from "../../http/axios";
import { GlobalContext } from "../../App";
import { toast } from "react-toastify";
import Auth from "../../auth/Auth";
const Login = () => {
  // --hide show pass --
  const [password, showPassword] = useState(false);
  const showpassword = () => [showPassword(!password)];
  const navigate = useNavigate();
  const { setSpinner } = useContext(GlobalContext);
  // -----use form login validation ----
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      email: "",
      password: "",
    },
  });
  // ----------------------- login api ------------------------
  const loginHandler = (data, e) => {
    e.preventDefault()
    setSpinner(true);
    try {
      axios.post(`${process.env.REACT_APP_BASE_URL}/api/admin/login`, data, { headers: headers })?.then((res) => {
        if (res?.status === 200) {
          Auth.login(res?.data)
          setSpinner(false);
          window.location.reload()
          navigate("/dashboard")
        }
      }).catch((error) => {
        toast.error(error?.response?.data?.message);
        setSpinner(false);
      })
    }
    catch (error) {
    }
  }
  return (
    <div>
      <div className="sign-info">
        <h2>Welcome Back</h2>
        <p>Please enter your details</p>
      </div>
      {/* ---------------------------------------login-form------------------------ */}
      <form className="form" onSubmit={handleSubmit(loginHandler)}>
        {/* --email input--- */}
        <div className="form-group">
          <CommonInput
            id="email"
            type="email"
            label="Email"
            placeholder="Enter email"
            inputclassName={`${errors.email ? " border-danger" : ""}`}
            register={{
              ...register("email", {
                required: true,
                pattern: /\S+@\S+\.\S+/,
              })
            }}
          />
          {errors.email?.type === "required" && (
            <p role="alert" className="alert-msg text-danger">
              Email is required
            </p>
          )}
          {errors.email?.type === "pattern" && (
            <p role="alert" className="text-danger">
              Incorrect email format
            </p>
          )}
          <img src="./assets/images/atrate.svg" alt=" " className="eye-open" />
        </div>
        {/* --- password input --- */}
        <div className="form-group">
          <div className="paddword-input">
            <CommonInput
              inputclassName={`${errors.password ? " border-danger" : ""}`}
              type={password ? "text" : "password"}
              label="Password"
              placeholder="Enter password"
              register={{ ...register("password", { required: true }) }}
            />
            {errors.password?.type === "required" && (
              <p className="alert-msg text-danger">
                Password is required
              </p>
            )}
            <div className="form-group-append " onClick={showpassword}>
              {password ? (
                <img src="./assets/images/eye-open.svg" alt="" className="eye-open" />
              ) : (
                <img src="./assets/images/eye-closed.svg" alt=" " className="eye-open" />
              )}
            </div>
          </div>
          <Link to="/forget_password" className="forget-password">
            Forgot Password?
          </Link>
        </div>
        <div className="form-group text-center">
          <button className="primery-btn w-100">Sign In</button>
        </div>
      </form>
    </div>
  );
};
export default Login;
