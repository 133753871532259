import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CommonInput from "../common-Component/form/CommonInput";
import Table from "../common-Component/table/Table";
import axios, { headers } from "../../http/axios";
import { GlobalContext } from "../../App";
import { toast } from "react-toastify";
import Pagination from "../common-Component/Pagination";
import CommonModal from "../common-Component/modal/CommonModal";

export function User() {
  const [userListing, setListing] = useState([]);
  const { setSpinner } = useContext(GlobalContext);
  const [filter, setFilter] = useState("");
  // ---- * pagination
  const [paginationCount, setPaginationCount] = useState({});
  const [pages, setPage] = useState(1);
  // --* searching
  const [searchEvent, setSearchEvent] = useState("");
  const [deleteUser, setDeleteUser] = useState({
    state: false,
    id: "",
  });
  // ---- delete api
  const deleteUserById = (id) => {
    setSpinner(true);
    axios
      .delete(`${process.env.REACT_APP_BASE_URL}/api/admin/users/${id}`, {
        headers: headers,
      })
      ?.then((res) => {
        if (res?.status === 200) {
          //   toast.success(res?.data?.message);
          userListingApi(searchEvent, pages, "");
          setSpinner(false);
          setDeleteUser({ state: false });
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        setSpinner(false);
      });
  };
  function convertDateFormat(inputDate) {
    // Convert the input date string to a Date object
    const dateObj = new Date(inputDate);

    // Extract the year, month, day, hours, and minutes
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getDate()).padStart(2, "0");
    const hours = dateObj.getHours();
    const minutes = String(dateObj.getMinutes()).padStart(2, "0");

    // Determine if it's AM or PM
    const amOrPm = hours >= 12 ? "PM" : "AM";

    // Adjust the hours to 12-hour format
    const adjustedHours = hours % 12 || 12;

    // Format the result date string
    const resultDate = `${year}-${month}-${day}, ${adjustedHours}:${minutes} ${amOrPm}`;

    return resultDate;
  }

  const handleSubscriptionView = (data) => {
    if (data === "gold") {
      return <span class="gold">Gold</span>;
    } else if (data === "silver") {
      return <span class="silver">Silver</span>;
    } else if (data === "platinum") {
      return <span class="platinum">Platinum</span>;
    } else {
      <span className="empty-data">-</span>;
    }
  };

  // ---- -------* listing api *------------
  const userListingApi = async (search, pages, page) => {
    setSpinner(true);
    try {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/api/admin/users${
            pages ? "?page=" + pages + `&size=${page > 10 ? page : 10}` : ""
          }${search ? `&search=` + search : ""}&filter=${filter}`,
          { headers: headers }
        )
        ?.then((res) => {
          if (res?.status >= 200) {
            setPaginationCount(res?.data?.data);
            setListing(
              res?.data?.data?.data?.map((item, index) => {
                return [
                  {
                    id: item?.id,
                    value:
                      res?.data?.data?.from + index ? (
                        res?.data?.data?.from + index
                      ) : (
                        <span className="empty-data">-</span>
                      ),
                  },
                  {
                    id: item?.id,
                    value: item?.name ? (
                      item?.name
                    ) : (
                      <span className="empty-data">-</span>
                    ),
                  },
                  {
                    id: item?.id,
                    value: item?.email ? (
                      item?.email
                    ) : (
                      <span className="empty-data">-</span>
                    ),
                  },
                  {
                    id: item?.id,
                    value: item?.phone_number ? (
                      "+" + item?.phone_number
                    ) : (
                      <span className="empty-data">-</span>
                    ),
                  },
                  {
                    id: item?.id,
                    value: item?.package ? (
                      handleSubscriptionView(item?.package)
                    ) : (
                      <span className="empty-data">-</span>
                    ),
                  },
                  {
                    id: item?.id,
                    value: item?.current_period_start ? (
                      convertDateFormat(item?.current_period_start)
                    ) : (
                      <span className="empty-data">-</span>
                    ),
                  },
                  {
                    id: item?.id,
                    value: item?.current_period_end ? (
                      convertDateFormat(item?.current_period_end)
                    ) : (
                      <span className="empty-data">-</span>
                    ),
                  },
                  {
                    id: item?.id,
                    value: (
                      <>
                        <Link to={`/users/user-details/${item?.id}`}>
                          <img
                            src="./assets/images/eye.svg"
                            alt=""
                            className="edit"
                          />
                        </Link>
                        <Link to={`/users/user-edit/${item?.id}`}>
                          <img
                            src="./assets/images/edit.svg"
                            alt=" "
                            className="edit"
                          />
                        </Link>
                        <img
                          src="./assets/images/trash.svg"
                          alt=" "
                          className="edit"
                          onClick={() =>
                            setDeleteUser({ state: true, id: item?.id })
                          }
                        />
                      </>
                    ),
                  },
                ];
              })
            );
            toast.success(res?.data?.message);
            setSpinner(false);
          }
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message);
          setSpinner(false);
        });
    } catch (error) {}
  };

  useEffect(() => {
    userListingApi("", pages, "");
  }, [pages]);
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      // Call your search function here
      userListingApi(searchEvent, 1, "");
      setPage(1);
    }, 500); // Delay time in milliseconds

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [searchEvent, filter]);

  // ---- -------* listing searching  *------------
  const handleSearch = (data) => {
    setFilter("");
    setSearchEvent(data);
    // setPage()
  };

  const handleSelect = (e) => {
    setSearchEvent("");
    setFilter(e.target.value);
  };
  return (
    <>
      <div className="title d-flex justify-content-between align-items-center">
        {/* ------------------ user header ------------------------- */}
        <h2>Users</h2>
        {/* -------- searchbar - -----------*/}
        <div className="d-flex search-dropdown">
          <CommonInput
            type="search"
            placeholder="Search..."
            className="search-box"
            icon="./assets/images/search.svg"
            value={searchEvent}
            onChange={(e) => handleSearch(e.target.value)}
          />
          {/* ---- filter according Package ---- */}
          <select
            value={filter}
            onChange={handleSelect}
            name="pets"
            id="pet-select"
          >
            <option value="">All</option>
            <option value="silver">Silver</option>
            <option value="gold">Gold</option>
            <option value="platinum">Platinum</option>
          </select>
        </div>
      </div>
      <div className="content table-content">
        <Table
          heading={[
            { value: "S.No.", id: 1 },
            { value: "Full Name", id: 2 },
            { value: "Email", id: 3 },
            { value: "Phone", id: 4 },
            { value: "Package", id: 5 },
            { value: "Subscribed Date", id: 6 },
            { value: "Due Date", id: 7 },
            { value: "Action", id: 8 },
          ]}
          rows={userListing}
        />
        {userListing?.length === 0 && (
          <div className="alert-text no-data">
            <div className="error-data">
              <p className="font-size-1.5"> No data found</p>
            </div>
          </div>
        )}
      </div>
      {/* ---------- pagination -------------- */}
      <div className="dash_pagination col-sm-12 mt-5">
        <nav aria-label="Page navigation example">
          {paginationCount?.total > 0 && (
            <Pagination
              totalPages={paginationCount?.last_page}
              currentPage={paginationCount?.current_page}
              page={pages}
              setPage={(page) => setPage(page)}
            />
          )}
        </nav>
      </div>

      {/* ---- delete modal ----- */}
      <CommonModal
        show={deleteUser.state}
        onHide={() =>
          setDeleteUser({
            state: false,
          })
        }
        className="add-post-modal"
        header={<></>}
        body={
          <>
            <h3>Delete User</h3>
            <p>Are you sure you want to delete this user?</p>
            <div className="btnContainer-modal d-flex">
              <button
                className="primery-btn me-3"
                onClick={() => deleteUserById(deleteUser?.id)}
              >
                Yes
              </button>
              <button
                className="secondry-btn"
                onClick={() =>
                  setDeleteUser({
                    state: false,
                  })
                }
              >
                No
              </button>
            </div>
          </>
        }
      />
    </>
  );
}
