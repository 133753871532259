import React from 'react';
import { useNavigate } from 'react-router-dom';
function BackNavigate({ url }) {
    const navigate = useNavigate()
    return (
        <div className="back-button pointer" onClick={() => { url ? navigate(url) : navigate(-1) }}>
            <img className='back-btn' src="./assets/images/down-arrow.svg" alt="back icon" />
        </div>
    );
}

export default BackNavigate;