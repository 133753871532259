

// import React from "react";
// import { Line } from "react-chartjs-2";
// import Chart from "chart.js/auto";

// const WeeklyChart = ({ graphData }) => {
//   const colorLine = (item) => {
//     if (item === "gold") {
//       return "#E0BF65";
//     } else if (item === "platinum") {
//       return "#AEA18A";
//     } else {
//       return "#9C9C9C";
//     }
//   };

//   // Extract data for each metal
//   const metals = Object.keys(graphData);
//   const datasets = metals.map((metal) => {
//     // Sort the data by date for each metal
//     const sortedData = graphData[metal].sort((a, b) => {
//       return new Date(a.date) - new Date(b.date);
//     });

//     // Get the current date in UTC (no need to convert to UTC, it's already in UTC)
//     const currentDate = new Date();
//     currentDate.setUTCHours(0, 0, 0, 0); // Set the time to the beginning of the day in UTC

//     // Calculate the start date (currentDate - 6 days) in UTC
//     const startDate = new Date(currentDate);
//     startDate.setUTCDate(currentDate.getUTCDate() - 6);

//     // Map data to each of the last 7 days (including the current day)
//     const countsWeekly = new Array(7).fill(0); // Initialize array with 0 counts for each day

//     sortedData.forEach((item) => {
//       const date = new Date(item.date);
//       date.setUTCHours(0, 0, 0, 0); // Set the time to the beginning of the day in UTC
//       if (date >= startDate && date <= currentDate) {
//         const dayIndex = (date.getUTCDay() - startDate.getUTCDay() + 7) % 7;
//         countsWeekly[dayIndex] += item.count;
//       }
//     });

//     return {
//       label: metal,
//       data: countsWeekly,
//       borderColor: colorLine(metal),
//       fill: false,
//     };
//   });

//   // Calculate the labels for the x-axis (current date and the previous 6 days) in UTC
//   const labels = Array.from({ length: 7 }, (_, index) => {
//     const date = new Date();
//     date.setUTCHours(0, 0, 0, 0); // Set the time to the beginning of the day in UTC
//     date.setUTCDate(date.getUTCDate() - 6 + index); // Subtract 6 days and add index to go forward in days
//     return date.toDateString();
//   });

//   // Chart.js configuration object
//   const chartData = {
//     labels: labels,
//     datasets: datasets.map((dataset) => ({
//       ...dataset,
//       fill: true, // Fill the area under the line
//       pointRadius: 0, // Hide the data points
//       tension: 0.4, // Set tension for cubic Bézier interpolation (0 = straight lines, 1 = full curves)
//     })),
//   };

//   // Chart.js options with animations
//   const chartOptions = {
//     responsive: true,
//     maintainAspectRatio: false,
//     interaction: {
//       mode: "index",
//       intersect: false,
//     },
//     plugins: {
//       legend: {
//         display: false,
//       },
//     },
//     scales: {
//       x: {
//         grid: {
//           display: false,
//         },
//       },
//       y: {
//         beginAtZero: true,
//       },
//     },
//     animation: {
//       // Enable chart and data point animations
//       duration: 1000, // Duration of animations in milliseconds
//       easing: "easeInOutQuart", // Easing function to control the animation speed
//       animateRotate: true, // Enable rotation animation for pie/doughnut charts
//       animateScale: true, // Enable scale animation for scale-based (non-pie) charts
//     },
//   };

//   return (
//     <div style={{ maxWidth: "100%", margin: "0 auto" }} className="graph">
//       <Line data={chartData} options={chartOptions} />
//     </div>
//   );
// };

// export default WeeklyChart;
import React from "react";
import { Line } from "react-chartjs-2";
import Chart from "chart.js/auto";

const WeeklyChart = ({ graphData }) => {
  const colorLine = (item) => {
    if (item === "gold") {
      return "#E0BF65";
    } else if (item === "platinum") {
      return "#AEA18A";
    } else {
      return "#9C9C9C";
    }
  };

  // Extract data for each metal
  const metals = Object.keys(graphData);
  const datasets = metals.map((metal) => {
    // Sort the data by date for each metal
    const sortedData = graphData[metal].sort((a, b) => {
      return new Date(a.date) - new Date(b.date);
    });

    // Get the current date in UTC (no need to convert to UTC, it's already in UTC)
    const currentDate = new Date();
    currentDate.setUTCHours(0, 0, 0, 0); // Set the time to the beginning of the day in UTC

    // Calculate the start date (currentDate - 6 days) in UTC
    const startDate = new Date(currentDate);
    startDate.setUTCDate(currentDate.getUTCDate() - 6);

    // Map data to each of the last 7 days (including the current day)
    const countsWeekly = new Array(7).fill(0); // Initialize array with 0 counts for each day

    sortedData.forEach((item) => {
      const date = new Date(item.date);
      date.setUTCHours(0, 0, 0, 0); // Set the time to the beginning of the day in UTC
      if (date >= startDate && date <= currentDate) {
        const dayIndex = (date.getUTCDay() - startDate.getUTCDay() + 7) % 7;
        countsWeekly[dayIndex] += item.count;
      }
    });

    return {
      label: metal,
      data: countsWeekly,
      borderColor: colorLine(metal),
      fill: false,
    };
  });

  // Calculate the labels for the x-axis (current date and the previous 6 days) in UTC
  const labels = Array.from({ length: 7 }, (_, index) => {
    const date = new Date();
    date.setUTCHours(0, 0, 0, 0); // Set the time to the beginning of the day in UTC
    date.setUTCDate(date.getUTCDate() - 6 + index); // Subtract 6 days and add index to go forward in days
    return date.toISOString().slice(0, 10); // Extract only the date portion (YYYY-MM-DD)
  });

  // Chart.js configuration object
  const chartData = {
    labels: labels,
    datasets: datasets.map((dataset) => ({
      ...dataset,
      fill: true, // Fill the area under the line
      pointRadius: 0, // Hide the data points
      tension: 0.4, // Set tension for cubic Bézier interpolation (0 = straight lines, 1 = full curves)
    })),
  };

  // Chart.js options with animations
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
      },
    },
    animation: {
      // Enable chart and data point animations
      duration: 1000, // Duration of animations in milliseconds
      easing: "linear", // Easing function to control the animation speed
      animateRotate: true, // Enable rotation animation for pie/doughnut charts
      animateScale: true, // Enable scale animation for scale-based (non-pie) charts
    },
  };

  return (
    <div style={{ maxWidth: "100%", margin: "0 auto" }} className="graph">
      <Line data={chartData} options={chartOptions} />
    </div>
  );
};

export default WeeklyChart;
