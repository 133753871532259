import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios, { headers } from "../../http/axios";
import { GlobalContext } from "../../App";
import { toast } from "react-toastify";
import BackNavigate from "../common-Component/BackNavigate";
import { Rating } from "react-simple-star-rating";
export function TestimonialsDetails() {
    const [detailListing, setdetailListing] = useState()
    const { setSpinner } = useContext(GlobalContext);
    const { id } = useParams();
    // ---- -------* listing api *------------
    const testimonialsDetailApi = async => {
        setSpinner(true);
        try {
            axios.get(`${process.env.REACT_APP_BASE_URL}/api/admin/testimonials/${id}`
                , { headers: headers })
                ?.then((res) => {
                    if (res?.status >= 200) {
                        setdetailListing(res?.data?.data);
                        toast.success(res?.data?.message);
                        setSpinner(false);
                    }
                }).catch((error) => {
                    toast.error(error?.response?.data?.message);
                    setSpinner(false);
                })
        } catch (error) {
        }
    }
    useEffect(() => {
        testimonialsDetailApi()
    }, [])

    return (
        <>
            <div className="title d-flex justify-content-between align-items-center">
                <h2><BackNavigate />Testimonial Details</h2>
            </div>
            <div className="content">
                <form className="testimonials-form add-blog-form">
                    <div className="form-group">
                        <b>Client Name</b>
                        <p>{detailListing?.client_name ? detailListing?.client_name : <span className="empty-data gray-text">---- </span>}</p>
                    </div>
                    <div className="form-group">
                        <b >Company Name</b><p>{detailListing?.company_name ? detailListing?.company_name : <span className="empty-data gray-text">---- </span>}</p>
                    </div>
                    <div className="form-group">
                        <b>Role</b><p>{detailListing?.role ? detailListing?.role : <span className="empty-data gray-text">---- </span>}</p>
                    </div>
                    <div className="form-group">
                        <b>Content</b>
                        <p>{detailListing?.content ? detailListing?.content : <span className="empty-data gray-text">---- </span>}</p>
                    </div>
                    <div className="form-group">
                        <b>Rating</b>
                        {/* <img src="assets/images/rating.svg" alt=" Rating" /> */}
                        <Rating
                            initialValue={detailListing?.rating}
                            allowHover={false}
                            disableFillHover={true}
                            fillColor={'#1065d1'}
                            readonly={true}
                        />
                    </div>
                </form>
            </div>
        </>
    )
}